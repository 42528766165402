import React, { useState, useMemo } from "react";
import DropdownContext from "./DropdownContext";

const DropdownProvider = ({ children }) => {
  const [dropdownValue, setDropdownValue] = useState(
    localStorage.getItem("companyId") || null
  );
  const [dropdownName, setDropdownName] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false); // New state for drawer
  const [machineIdContext, setMachineIdContext] = useState(
    localStorage.getItem("machineId") || null
  );
  const [transactionFromDate, setTransactionFromDate] = useState(
    localStorage.getItem("fromDate") || null
  );

  const contextValue = useMemo(
    () => ({
      dropdownValue,
      setDropdownValue,
      dropdownName, // New
      setDropdownName, // New
      machineIdContext,
      setMachineIdContext,
      transactionFromDate,
      setTransactionFromDate,
    }),
    [
      dropdownValue,
      setDropdownValue,
      dropdownName,
      setDropdownName,
      transactionFromDate,
      setTransactionFromDate,
    ]
  );

  return (
    <DropdownContext.Provider value={contextValue}>
      {children}
    </DropdownContext.Provider>
  );
};

export default DropdownProvider;
