import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { baseUrl, baseUrladmin } from "../../../assets/js/config/config.js";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "../style.css";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

function EditDevice() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [rows, setPosts] = useState([]);
  const AccessToken = localStorage.getItem("accessToken");
  const fname = localStorage.getItem("firstName");
  const [destcmpid, setdestcmpid] = useState();
  const [devicegroup, setdeviceGroup] = useState();
  const [Deviceclassgroups, setDeviceclassgroups] = useState([]);
  const navigate = useNavigate();
  const [companytypes, setCompanytypes] = useState([]);
  const [Check, setCheck] = useState();
  const [editdata, setEditdata] = useState({
    deviceId: "",
    deviceClassGroupId: "",
    companyId: "",
    collGroupId: "",
    deviceAddress: "",
    deviceName: "",
    deviceGroupName: "",
    active: true,
    companyName: "",
    collegeGroupName: "",
    deviceClassGroupName: "",
    campusId: "",
    collegeGroupId: "",
    collegeGroupName: "",
  });
  const { state } = useLocation();
  const [campusId, setCampusId] = useState();
  const [campusDropdown, setCampusDropdown] = useState([]);

  useEffect(() => {
    fetch(baseUrladmin + "company/getAllCompaniesForAdmin", {
      headers: {
        Authorization: `Bearer ${AccessToken}`,
        "Content-type": "application/json; charset=UTF-8",
        companyId: localStorage.getItem("companyId"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == -1) {
          setCompanytypes([]);
        } else {
          setCompanytypes(data.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    fetch(baseUrladmin + "deviceclassgroup/findAllDeviceClassGroups", {
      headers: {
        Authorization: `Bearer ${AccessToken}`,
        "Content-type": "application/json; charset=UTF-8",
        companyId: localStorage.getItem("companyId"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setDeviceclassgroups(data.data);
        const deviceClassGroup = Deviceclassgroups[0];
        setEditdata({
          ...editdata,
          deviceClassGroupId: deviceClassGroup?.deviceClassGroupId,
          deviceClassGroupName: deviceClassGroup?.deviceClassGroupName,
        });
      })
      .catch((err) => {
        console.log(err.message);
      });

    const bodyFormData = {
      deviceId: state?.deviceId,
      deviceClassGroupId: state?.deviceClassGroupId,
      companyId: state?.companyId,
    };
    axios({
      method: "post",
      url: baseUrladmin + "device/getByDeviceId",
      data: bodyFormData,
      config: {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
        },
      },
    })
      .then((response) => response)
      .then((data) => {
        setEditdata(data.data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  useEffect(() => {
    fetch(baseUrladmin + "collegegroup/getCollegeGroupsByCollegeId", {
      headers: {
        Authorization: `Bearer ${AccessToken}`,
        "Content-type": "application/json; charset=UTF-8",
        companyId: localStorage.getItem("companyId"),
      },
      method: "POST",
      body: JSON.stringify({ companyId: editdata.companyId || null }),
    })
      .then((response) => response.json())
      .then((data) => {
        setCampusDropdown(data?.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [editdata]);

  const handleSelectCompany = (e) => {
    setdestcmpid(e.target.value);
    console.log(destcmpid);
    const { value } = event.target;
    const campusData = companytypes?.find(
      (item) => item?.companyName === value
    );
    setEditdata({
      ...editdata,
      companyId: campusData?.companyId,
      companyName: campusData?.companyName,
    });
  };
  const handleCampusSelect = (e) => {
    // setCampusId(e.target.value);
    const { value } = event.target;
    const campusData = campusDropdown?.find(
      (item) => item.collegeGroupName === value
    );
    setEditdata({
      ...editdata,
      collegeGroupId: campusData?.collegeGroupId,
      collegeGroupName: campusData?.campusGroupName,
    });
  };
  const handleSelect = (e) => {
    setdeviceGroup(e.target.value);
    const { value } = event.target;
    const campusData = Deviceclassgroups?.find(
      (item) => item?.companyName === value
    );
    setEditdata({
      ...editdata,
      deviceClassGroupId: campusData?.deviceClassGroupId,
      deviceClassGroupName: campusData?.deviceClassGroupName,
    });
  };
  const onSubmit = (data) => {
    const bodyFormData = {
      deviceId: editdata.deviceId,
      active: data.active ? data.active : editdata.active,
      companyId: editdata.companyId,
      collGroupId: editdata.collegeGroupId || null,
      deviceAddress: data.deviceAddress
        ? data.deviceAddress
        : editdata.deviceAddress,
      deviceClassGroupId: editdata.deviceClassGroupId,
      deviceGroupName: data.deviceGroupName
        ? data.deviceGroupName
        : editdata.deviceGroupName,
      deviceName: data.deviceName ? data.deviceName : editdata.deviceName,
      updatedAt: new Date(),
    };
    axios({
      method: "put",
      url: baseUrladmin + "device/updateDevice",
      data: bodyFormData,
      config: {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
        },
      },
    })
      .then((data) => {
        if (data.data.status == 200) {
          alert("Device Updated Successfully");
          navigate("/devicedata");
        } else {
          alert(data.data.message);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Edit Device
              </MDTypography>
            </MDBox>
            <div className="App container fpd col-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div
                  className="row"
                  style={{
                    width: "965px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="col-3 mb-2">
                    <label htmlFor="companyName">College Name</label>
                    <select
                      onChange={handleSelectCompany}
                      style={{ width: "294px" }}
                      value={
                        editdata?.companyName !== ""
                          ? editdata.companyName
                          : null
                      }
                    >
                      {companytypes.map((companytype) => (
                        <option
                          id={companytype.companyId}
                          value={companytype?.companyName}
                        >
                          {companytype?.companyName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-3 mb-2">
                    <label htmlFor="collegeGroupId">Campus</label>
                    <select
                      value={
                        editdata.collegeGroupName !== ""
                          ? editdata.collegeGroupName
                          : null
                      }
                      onChange={handleCampusSelect}
                      style={{ width: "294px" }}
                    >
                      {!campusDropdown && (
                        <option value=""> ------- No Campus ------ </option>
                      )}
                      {campusDropdown?.map((Dev) => (
                        <option
                          id={Dev.collegeGroupId}
                          value={Dev.collegeGroupName}
                        >
                          {Dev.collegeGroupName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-3 mb-2">
                    <label htmlFor="deviceType">Device Type</label>
                    <select
                      value={
                        editdata.deviceClassGroupName !== ""
                          ? editdata.deviceClassGroupName
                          : null
                      }
                      onChange={handleSelect}
                      style={{ width: "294px" }}
                      defaultValue={editdata.deviceClassGroupName}
                    >
                      {Deviceclassgroups.map((Devcg) => (
                        <option
                          id={Devcg.deviceClassGroupId}
                          value={Devcg.deviceClassGroupName}
                        >
                          {Devcg.deviceClassGroupName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    width: "965px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="mb-2">
                    <label htmlFor="deviceAddress">Device Address</label>
                    <input
                      className="form-control"
                      placeholder="deviceAddress"
                      type="text"
                      name="deviceAddress"
                      disabled
                      defaultValue={editdata.deviceAddress}
                      {...register("deviceAddress")}
                    />
                  </div>
                  <div className="col-3 mb-2">
                    <label htmlFor="deviceName">Device Name</label>
                    <input
                      className="form-control"
                      placeholder="Device Name"
                      type="text"
                      name="deviceName"
                      defaultValue={editdata.deviceName}
                      {...register("deviceName")}
                    />
                  </div>
                  <div className="mb-2">
                    <input
                      type="checkbox"
                      id="active"
                      name="active"
                      checked={editdata.active}
                      onChange={(e) =>
                        setEditdata({
                          ...editdata,
                          active: e.target.checked,
                        })
                      }
                      // defaultValue={editdata.active}
                    />
                    <label for="active"> Is Active</label>
                  </div>
                </div>

                <div className="mb-3">
                  <MDButton
                    variant="outlined"
                    type="submit"
                    color="info"
                    size="small"
                  >
                    Update
                  </MDButton>
                </div>
              </form>
            </div>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default EditDevice;
