import React from "react";
import tenor from "../../assets/images/tenor.gif";

function Spinner() {
  return (
    <div>
      <img
        src={tenor}
        style={{ margin: "auto", paddingTop: "123px", display: "block", width: "200px" }}
        alt="Loading..."
      />
    </div>
  );
}

export default Spinner;
