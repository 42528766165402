import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import axios from "axios";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { baseUrl, baseUrladmin } from "../../assets/js/config/config.js";
import moment from "moment";
import Icon from "@mui/material/Icon";
import { Link, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import DatePicker from "react-datepicker";

function LowSignalDevices() {
    const [posts, setPosts] = useState([]);
    const AccessToken = localStorage.getItem("accessToken");
    const companyId = localStorage.getItem("companyId");
    const [messages, setMessages] = useState([]);
    const navigate = useNavigate();
    var date = new Date();
    date.setDate(date.getDate() - 7);
    const [startDate, setStartDate] = useState(date);
    const [endDate, setEndDate] = useState(new Date());
    const [isdata, setIsdata] = useState(true);

    function padTo2Digits(num) {
        return num.toString().padStart(2, "0");
    }
 
    function formatDate(date) {
        return [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join(
        "/"
        );
    }

    const serachData = () => {
        const bodyFormData = {
        companyId: companyId,
        fromDate: formatDate(startDate),
        toDate: formatDate(endDate)
        };
        axios({
        method: "post",
        url: baseUrladmin + "phoneStatus/getLowSignalPhones",
        data: bodyFormData,
        config: {
            headers: {
            Accept: "application/json",
            Authorization: `Bearer ${AccessToken}`,
            "Content-Type": "application/json; charset=utf-8",
            "companyId": localStorage.getItem("companyId"),
            },
        },
        })
        .then((data) => {
            if (data.data.status == -1) {
            setIsdata(true);
            }
            else {
            setIsdata(false);
            setMessages(data.data.data);
            }
        })
        .catch((err) => {
            console.log(err.message);
        });
    };

    const rows = messages;
    const columns = [
        { Header: "Device Address", accessor: "deviceAddress", width: "10%", align: "left" },
        { Header: "Name", accessor: "deviceName", width: "10%", align: "left" },
        { Header: "Signal", accessor: "phoneSignal", width: "10%", align: "left" },
        { Header: "Duration", accessor: "duration", width: "10%", align: "left" },
        { Header: "Location", accessor: "location", width: "10%", align: "left" },
        { Header: "College", accessor: "companyName", width: "10%", align: "left" },
        {
            Header: "Created Time",
            accessor: d => {
                return moment(d.createdAt)
                    .local()
                    .format("DD-MM-YYYY hh:mm:ss a")
            },
            width: "10%",
            align: "left",
        },
        {
            Header: "Updated Time",
            accessor: d => {
                return moment(d.updatedAt)
                    .local()
                    .format("DD-MM-YYYY hh:mm:ss a")
            },
            width: "10%",
            align: "left",
        },
    ];
    return (
        <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
            <Grid item xs={12}>
                <Card>
                <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info">
                    <MDTypography variant="h6" color="white">
                    Low Signal Phones
                    </MDTypography>
                </MDBox>
                <Grid container spacing={3} style={{ marginTop: "0px", marginLeft: "0px" }}>
                    <MDBox mb={2} style={{ marginTop: "7px", marginLeft: "12px", fontSize: "15px" }}>
                        From Date&nbsp;
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                    </MDBox>
                    <MDBox mb={2} style={{ marginTop: "7px", marginLeft: "-30px", fontSize: "15px" }}>
                    To Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                    </MDBox>
                    <MDBox mb={2} style={{ marginTop: "20px", marginLeft: "45px", fontSize: "15px" }}>
                    <MDButton variant="outlined" onClick={serachData} color="info" size="small">
                        Search
                    </MDButton>
                    </MDBox>
                </Grid>
                <MDBox pt={3}>
                    {!isdata ? (
                    <DataTable
                        table={{ columns, rows }}
                        isSorted={1}
                        entriesPerPage={1}
                        showTotalEntries={1}
                        noEndBorder
                    />
                    ) : (
                    <MDBox mb={2} style={{ marginTop: "7px", marginLeft: "12px", fontSize: "15px" }}>
                        No Data Found
                    </MDBox>
                    )}
                </MDBox>
                </Card>
            </Grid>
            </Grid>
        </MDBox>
        </DashboardLayout>
    );
}

export default LowSignalDevices;
