import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import {baseUrl,baseUrladmin} from "../../assets/js/config/config.js";

function Devices() {
  const AccessToken = localStorage.getItem("accessToken");
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    fetch(baseUrladmin +"device/getAllCompanyDevices", {
      headers: {
        Authorization: `Bearer ${AccessToken}`,
        "Content-type": "application/json; charset=UTF-8",
        "companyId":  localStorage.getItem("companyId"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPosts(data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  const rows = posts;
  const columns = [
    { Header: "Company Name", accessor: "companyName", width: "10%", align: "left" },
    { Header: "Device Address", accessor: "deviceAddress", width: "10%", align: "left" },
    { Header: "Device GroupName", accessor: "deviceGroupName", width: "10%", align: "left" },
    { Header: "Device MQQTT ID", accessor: "deviceMQTTId", width: "10%", align: "left" },
    { Header: "Device MQQTT User", accessor: "deviceMQTTUser", width: "10%", align: "left" },
  ];
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Devices
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={1}
                  entriesPerPage={1}
                  showTotalEntries={1}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Devices;
