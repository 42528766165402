// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState, useEffect } from "react";
import axios from "axios";
import {baseUrl,baseUrladmin} from "../../../../assets/js/config/config.js";

function ComplexStatisticsCard({ color, title, count, percentage, icon }) {
  const AccessToken = localStorage.getItem("accessToken");

  const handleChange = (e) => {
    if (percentage.amount == false) {
      var message="ON"
    } else {
      var message="OFF"
    }
    //setChecked(!checked);
    const bodyFormData = {
      deviceAddress: e.target.id,
      message: message,
    };
    axios({
      method: "post",
      url: baseUrladmin + "message/sendDownlink",
      data: bodyFormData,
      config: {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${AccessToken}`,
          "Content-Type": "application/json; charset=utf-8",
          "companyId":  localStorage.getItem("companyId"),
        },
      },
    })
      .then((data) => {
        if (data.status === 200) {
          alert("Success");
          window.location.reload(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const [checked, setChecked] = useState(false);
  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
        <MDBox
          variant="gradient"
          bgColor={color}
          color={color === "light" ? "dark" : "white"}
          coloredShadow={color}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          mt={-3}
        >
          <Icon fontSize="medium" color="inherit">
            {icon}
          </Icon>
        </MDBox>
        <MDBox textAlign="right" lineHeight={1.25}>
          <MDTypography variant="button" fontWeight="light" color="text">
            {title}
          </MDTypography>
          {/* <label className="switch" style={{ margin: "0px" }}>
            <input type="checkbox" onChange={handleChange} className="switch-input" checked={percentage.amount}  id={percentage.label} name={percentage.label} />
            <span className="switch-label" ></span>
            <span className="switch-handle"></span>
          </label> */}
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox pb={2} px={2}>
        <MDTypography component="p" variant="button" color="text" display="flex">
          <MDTypography
            component="span"
            variant="button"
            fontWeight="bold"
            color={percentage.color}
          >
            { }
          </MDTypography>
          &nbsp;{count}
        </MDTypography>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ComplexStatisticsCard
ComplexStatisticsCard.defaultProps = {
  color: "info",
  percentage: {
    color: "success",
    text: "",
    label: "",
  },
};

// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    status: PropTypes.string,
    amounton: PropTypes.string,
    amountoff: PropTypes.string,
  }),
  icon: PropTypes.node.isRequired,
};

export default ComplexStatisticsCard;
