import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { baseUrl, baseUrladmin } from "../../../assets/js/config/config.js";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "../style.css";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

function AddCustomer() {
    const AccessToken = localStorage.getItem("accessToken");
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const [Check, setCheck] = useState(false);
    const [companytypes, setCompanytypes] = useState([]);
    const [cmptype, setcmptype] = useState();

    const handlSelect = (e) => {
        setcmptype(e.target.value);
    }

    useEffect(() => {
        fetch(baseUrladmin + "comptypes/getAllCompanyTypes", {
            headers: {
                Authorization: `Bearer ${AccessToken}`,
                "Content-type": "application/json; charset=UTF-8",
                "companyId": localStorage.getItem("companyId"),
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setCompanytypes(data.data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);
    const onSubmit = (data) => {
        const bodyFormData = {
            "active": Check ? Check : false,
            "balanceAmount": data.balanceAmount,
            "companyAddress": data.companyAddress,
            "companyCIN": data.companyCIN,
            "companyContactEmail": data.companyContactEmail,
            "companyContactMobile": data.companyContactMobile,
            "companyContactName": data.companyContactName,
            "companyContactPhone": data.companyContactPhone,
            "companyEmail": data.companyEmail,
            "companyGST": data.companyGST,
            "companyName": data.companyName,
            "companyPhone": data.companyPhone,
            "companyTin": data.companyTin,
            "companyType": data.companyType,
            "companyTypeId": cmptype ? cmptype : companytypes[0].companyTypeId,
            "country": data.country,
            "postalCode": data.postalCode,
            "stateName": data.stateName
        };
        axios({
            method: "post",
            url: baseUrladmin + "company/addCompanyMaster",
            data: bodyFormData,
            config: {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json; charset=utf-8",
                },
            },
        })
            .then((data) => {
                if (data.data.status == 200) {
                    alert("Customer Added Successfully");
                    navigate("/customers");
                }
                else {
                    alert(data.data.message);
                    window.location.reload(false);
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={6}><Grid item xs={12}>
                <Card>
                    <MDBox
                        mx={2}
                        mt={3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                    >
                        <MDTypography variant="h6" color="white">
                            Add Customer
                        </MDTypography>
                    </MDBox>
                    <div className='App container fpd col-6'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='row' style={{ width: "965px" }}>
                                <div className='mb-1'>
                                    <label htmlFor='companyName'>Name</label>
                                    <input
                                        className={`form-control ${errors.companyName ? 'is-invalid' : ''}`}
                                        type='text'
                                        name='companyName'
                                        {...register("companyName", { required: true, minLength: 5, maxLength: 100 })}
                                    />
                                </div>
                                <div className='mb-1'>
                                    <label htmlFor='companyPhone'>Phone</label>
                                    <input
                                        className={`form-control ${errors.companyPhone ? 'is-invalid' : ''}`}
                                        type='text'
                                        name='companyPhone'
                                        {...register("companyPhone", { required: true, pattern: { value: /[7-9][0-9]{9}$/i } })}
                                    />
                                </div>
                                <div className='mb-1'>
                                    <label htmlFor='companyEmail'>Email</label>
                                    <input
                                        className={`form-control ${errors.companyEmail ? 'is-invalid' : ''}`}
                                        type='email'
                                        name='companyEmail'
                                        {...register("companyEmail", { required: true })}
                                    />
                                </div>
                                <div className='mb-1'>
                                    <label htmlFor='stateName'>State Name</label>
                                    <input
                                        className={`form-control ${errors.stateName ? 'is-invalid' : ''}`}
                                        type='text'
                                        name='stateName'
                                        {...register("stateName", { required: true, minLength: 3, maxLength: 20 })}
                                    />
                                </div>

                            </div>

                            <div className='row' style={{ width: "965px" }}>
                                <div className='mb-1'>
                                    <label htmlFor='country'>Country</label>
                                    <input
                                        className={`form-control ${errors.country ? 'is-invalid' : ''}`}
                                        type='text'
                                        name='country'
                                        {...register("country", { required: true, minLength: 3, maxLength: 20 })}
                                    />
                                </div>
                                <div className='mb-1'>
                                    <label htmlFor='postalCode'>Postal Code</label>
                                    <input
                                        className={`form-control ${errors.postalCode ? 'is-invalid' : ''}`}
                                        type='text'
                                        name='postalCode'
                                        minLength= {6}
                                        maxLength= {6}
                                        {...register("postalCode", { required: true })}
                                    />
                                </div>
                                <div className='mb-1'>
                                    <label htmlFor='companyTin'>Tin</label>
                                    <input
                                        className={`form-control ${errors.companyTin ? 'is-invalid' : ''}`}
                                        type='text'
                                        name='companyTin'
                                        {...register("companyTin", { required: true })}
                                    />
                                </div>
                                <div className='mb-1'>
                                    <label htmlFor='companyType'>Company Type</label>
                                    <select onChange={handlSelect} style={{ "width": "216px" }}>
                                        {companytypes.map((companytype) => (
                                            <option value={companytype.companyTypeId}>{companytype.companyType}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='row' style={{ width: "965px" }}>
                                <div className='mb-1'>
                                    <label htmlFor='companyGST'>GST</label>
                                    <input
                                        className={`form-control ${errors.companyGST ? 'is-invalid' : ''}`}
                                        type='text'
                                        name='companyGST'
                                        {...register("companyGST", { required: true })}
                                    />
                                </div>

                                {/* <div className='mb-1'>
                                    <label htmlFor='balanceAmount'>Balance Amount</label>
                                    <input
                                        className={`form-control ${errors.balanceAmount ? 'is-invalid' : ''}`}
                                        type='text'
                                        name='balanceAmount'
                                        {...register("balanceAmount", { required: true })}
                                    />
                                </div> */}
                                <div className='mb-1'>
                                    <label htmlFor='companyContactName'>Contact Name</label>
                                    <input
                                        className={`form-control ${errors.companyContactName ? 'is-invalid' : ''}`}
                                        type='text'
                                        name='companyContactName'
                                        {...register("companyContactName", { required: true })}
                                    />
                                </div>

                                <div className='mb-1'>
                                    <label htmlFor='companyContactMobile'>Contact Mobile</label>
                                    <input
                                        className={`form-control ${errors.companyContactMobile ? 'is-invalid' : ''}`}
                                        type='text'
                                        name='companyContactMobile'
                                        {...register("companyContactMobile", { required: true, pattern: { value: /[7-9][0-9]{9}$/i } })}
                                    />
                                </div>
                                
                                <div className='mb-1'>
                                    <label htmlFor='companyContactEmail'>Contact Email</label>
                                    <input
                                        className={`form-control ${errors.companyContactEmail ? 'is-invalid' : ''}`}
                                        type='email'
                                        name='companyContactEmail'
                                        {...register("companyContactEmail", { required: true })}
                                    />
                                </div>
                            </div>
                            <div className='row' style={{ width: "965px" }}>
                                <div className='mb-1'>
                                    <label htmlFor='companyCIN'>Company CIN</label>
                                    <input
                                        className={`form-control ${errors.companyCIN ? 'is-invalid' : ''}`}
                                        type='text'
                                        name='companyCIN'
                                        {...register("companyCIN", { required: true })}
                                    />
                                </div>
                                <div className='mb-1'>
                                    <label htmlFor='companyAddress'>Company Address</label>
                                    <input
                                        className={`form-control ${errors.companyAddress ? 'is-invalid' : ''}`}
                                        type='text'
                                        name='companyAddress'
                                        {...register("companyAddress", { required: true })}
                                    />
                                </div>

                                <div className='mb-1'>
                                    <input type="checkbox" id="active" name="active" onChange={(e) => setCheck(e.target.checked)} />
                                    <label for="active"> Is Active</label>
                                </div>
                            </div>
                            <div className='mb-3'>
                                <MDButton variant="outlined" type="submit" color="info" size="small">
                                    Add
                                </MDButton>
                            </div>
                        </form>
                    </div>
                </Card>
            </Grid>
            </Grid>
        </DashboardLayout>
    );
}

export default AddCustomer;
