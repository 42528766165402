import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import axios from "axios";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { baseUrl, baseUrladmin } from "../../assets/js/config/config.js";
import moment from "moment";
import Icon from "@mui/material/Icon";
import { Link, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";

function MyTasks() {
    const [posts, setPosts] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        const bodyFormData = {
            "companyId":  localStorage.getItem("companyId"),
        }
        axios({
            method: "post",
            url: baseUrladmin + "mytaskmaster/getAllTasksByCompanyId",
            data: bodyFormData,
            config: {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json; charset=utf-8",
                    "companyId":  localStorage.getItem("companyId"),
                },
            },
        })
            .then((data) => {
                setPosts(data.data.data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);
    const addTask = (e) => {
        navigate("/addTask");
    }
    const editTask = row => {
        navigate("/editTask", { state: row });
    }
    const closeTask = (row) => {
        const bodyFormData = row
        axios({
            method: "post",
            url: baseUrladmin + "mytaskmaster/closeTaskMaster",
            data: bodyFormData,
            config: {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json; charset=utf-8",
                    "companyId":  localStorage.getItem("companyId"),
                },
            },
        })
            .then((data) => {
                if (data.data.status == 200) {
                    alert(" Task Closed Successfully");
                    window.location.reload(false);
                }
                else {
                    alert(data.data.message);
                    window.location.reload(false);
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    }
    const rows = posts;
    const columns = [
        { Header: "Task Name", accessor: "taskName", width: "10%", align: "left" },
        { Header: "Task", accessor: "taskDescription", width: "10%", align: "left" },
        {
            Header: "Status",
            id: "taskStatus",
            Cell: row => (
                row.row.original.taskStatus == true ?
                    <span>True</span> :
                    <span>False</span>
            ),
            align: "center"
        },
        {
            Header: "Created Time",
            accessor: d => {
                return moment(d.createdAt)
                    .local()
                    .format("DD-MM-YYYY hh:mm:ss a")
            },
            width: "10%",
            align: "left",
        },
        {
            Header: "Updated Time",
            accessor: d => {
                return moment(d.updatedAt)
                    .local()
                    .format("DD-MM-YYYY hh:mm:ss a")
            },
            width: "10%",
            align: "left",
        },
        {
            Header: 'Close',
            accessor: "close",
            Cell: row => (
                row.row.original.taskStatus == false ?
                <div>
                    <a style={{ cursor: 'pointer' }} onClick={() => { if (window.confirm('Are you sure you wish to close  this task?')) closeTask(row.row.original) }}>
                        <i>Close</i></a>
                </div> :
                <span></span>
            ),
            align: "center"
        },
        {
            Header: 'Action',
            accessor: "action",
            Cell: row => (
                row.row.original.taskStatus == false ?
                <div>
                    <a style={{ cursor: 'pointer' }} onClick={e => editTask(row.row.original)}><i class="material-icons">mode_edit</i></a>
        
                </div>:
                <span></span>
            ),
            align: "center"
        },
    ];
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox display="flex" bgColor="info" coloredShadow="info" borderRadius="lg" justifyContent="space-between" alignItems="center" p={3}>
                                <MDBox>
                                    <MDTypography variant="h6" gutterBottom color="white">
                                        My Tasks
                                    </MDTypography>
                                </MDBox>
                                <MDBox color="text" px={2} >
                                    <MDButton style={{color:"white"}} variant="outlined" type="button" color="info" size="small" onClick={addTask}>
                                        Add Task
                                    </MDButton>
                                </MDBox>
                            </MDBox>
                            <MDBox pt={3}>
                                <DataTable
                                    table={{ columns, rows }}
                                    isSorted={1}
                                    entriesPerPage={1}
                                    showTotalEntries={1}
                                    noEndBorder
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}

export default MyTasks;
