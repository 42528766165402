import { useContext, useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/Marketing_03042023.jpg";
import axios from "axios";
import { baseUrl, baseUrladmin } from "../../../assets/js/config/config.js";
import DropdownContext from "shared/ContextStore/DropdownContext.js";

function Basic() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { dropdownValue, setDropdownValue } = useContext(DropdownContext);
  const navigate = useNavigate();

  const addPosts = async () => {
    const bodyFormData = {
      username: username,
      password: password,
    };
    axios({
      method: "post",
      url: baseUrl + "auth/signin",
      data: bodyFormData,
      config: {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
        },
      },
    })
      .then((response) => response)
      .then((data) => {
        if (data.data.accessToken) {
          setDropdownValue(data?.data?.companyId);
          localStorage.setItem("accessToken", data.data.accessToken);
          localStorage.setItem("companyId", data.data.companyId);
          localStorage.setItem("roles", data.data.roles);
          localStorage.setItem("firstName", data.data.firstName);

          setUsername("");
          setPassword("");
          navigate("/dashboard", { state: data.data.roles });
          window.location.reload(true);
        } else {
          alert("Wrong UserName or Password");
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    addPosts(username, password);
  };
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Email/PhoneNumber"
                fullWidth
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </MDBox>
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox> */}
            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                sign in
              </MDButton>
            </MDBox>
            {/* <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox> */}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
