// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { useState, useEffect } from "react";
// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
import { baseUrl, baseUrladmin } from "../../assets/js/config/config.js";
// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import Spinner from "../tables/spinner";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Icon from "@mui/material/Icon";
import { useLocation } from "react-router-dom";

function CampusManager() {
  const { sales, tasks } = reportsLineChartData;
  const AccessToken = localStorage.getItem("accessToken");
  //const companyId = localStorage.getItem("companyId");
  const [rows, setPosts] = useState([]);
  const storedCollege = sessionStorage.getItem("college");
  const college = storedCollege ? JSON.parse(storedCollege) : {};

  const [editdata, setEditdata] = useState({
    companyName: college.campusName || "",
    companyId: college.campusId || "",
    collegeGroupId: "",
  });
  const [loading, setLoading] = useState(true);
  const [showdata, setShowdata] = useState(false);
  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [campusDropdown, setDeviceclassgroups] = useState([]);
  const [editAddState, setEditAddState] = useState(false);
  const [campusName, setCampusName] = useState("");
  const [error, setError] = useState("");
  const [showEditData, setShowEditData] = useState("");

  useEffect(() => {
    const storedCollege = sessionStorage.getItem("college");
    if (storedCollege) {
      const collegeData = JSON.parse(storedCollege);
      setEditdata({
        ...editdata,
        companyName: collegeData.companyName,
        companyId: collegeData.companyId,
      });
    }
  }, []);

  useEffect(() => {
    fetch(baseUrladmin + "company/getAllCompaniesForAdmin", {
      headers: {
        Authorization: `Bearer ${AccessToken}`,
        "Content-type": "application/json; charset=UTF-8",
        companyId: localStorage.getItem("companyId"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setDeviceclassgroups(data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
    // here
  }, []);
  const serachData = () => {
    const bodyFormData = {
      companyId: editdata.companyId,
      //deviceId: deviceGroup,
      // deviceClassGroupId: ddnvalue,
    };
    axios({
      method: "post",
      url: baseUrladmin + "collegegroup/getCollegeGroupsByCollegeId",
      data: bodyFormData,
      config: {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${AccessToken}`,
          "Content-Type": "application/json; charset=utf-8",
          companyId: localStorage.getItem("companyId"),
        },
      },
    })
      .then((data) => {
        if (data.data.status == 200) {
          setShowdata(true);
          setPosts(data.data.data);
          setLoading(false);
        } else {
          setLoading(false);
          setShowdata(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    serachData();
  }, [editdata]);

  const roles = localStorage.getItem("roles");
  if (roles) {
    if (roles.includes("ROLE_COLLEGEGROUP")) {
      ddnvalue = "d41d8cd9-8f00-b204-e980-0998ecf8427e";
      ddndisable = true;
    } else if (roles.includes("ROLE_COLLEGE")) {
      ddnvalue = "d41d8cd9-8f00-b204-e980-0998ecf8427e";
      ddndisable = true;
    }
  }

  const handleSelect = (e) => {
    const { value } = event.target;
    const campusData = campusDropdown?.find(
      (item) => item.companyName === value
    );
    setEditdata({
      ...editdata,
      companyId: campusData?.companyId,
      companyName: campusData?.companyName,
    });
    sessionStorage.setItem(
      "college",
      JSON.stringify({
        companyId: campusData?.companyId,
        companyName: campusData?.companyName,
      })
    );
  };

  const updateDevName = (row) => {
    if (
      campusName === "" ||
      campusName === null ||
      campusName.toString().length < 3 ||
      campusName.toString().length > 50
    ) {
      setError(true);
      return;
    }
    setError(false);
    const bodyFormData = {
      companyId: editAddState ? showEditData.companyId : editdata.companyId,
      collegeGroupName: campusName,
      collegeGroupId: editAddState ? showEditData.collegeGroupId : "",
    };
    const api = editAddState
      ? "collegegroup/editCollegeGroup"
      : "collegegroup/addCollegeGroup";
    axios({
      method: editAddState ? "put" : "post",
      url: baseUrladmin + api,
      data: bodyFormData,
      config: {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
        },
      },
    })
      .then((response) => response)
      .then((data) => {
        if (data.data.status == 200) {
          alert("Success");
          serachData();
          setShow(false);
        } else {
          alert("failer");
          setShow(true);
        }
      })
      .catch((err) => {
        alert("Please Enter Name Properly");
      });
  };
  const transfer = (e) => {
    setError(false);
    setCampusName("");
    setEditAddState(false);
    setShow(true);
  };
  const editOpen = (row) => {
    setShowEditData(row);
    setCampusName("");
    setCampusName(campusName || row.collegeGroupName);
    setEditAddState(true);
    setShow(true);
  };

  // const deleteOpen = (row) => {
  //   const bodyFormData = row;
  //   axios({
  //     method: "delete",
  //     url: baseUrladmin + "device/deleteDevice",
  //     data: bodyFormData,
  //     config: {
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json; charset=utf-8",
  //       },
  //     },
  //   })
  //     .then((data) => {
  //       if (data.data.status == 200) {
  //         alert("Device Deleted Successfully");
  //         window.location.reload(false);
  //       } else {
  //         alert(data.data.message);
  //         window.location.reload(false);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // };

  const columns = [
    // {
    //   Header: "Device Name",
    //   accessor: "deviceName",
    //   width: "5%",
    //   align: "left",
    // },

    {
      Header: "company Name",
      accessor: "companyName",
      width: "10%",
      align: "left",
    },
    // {
    //   Header: "Last Seen",
    //   accessor: (d) => {
    //     return moment(d.updatedAt).local().format("DD-MM-YYYY HH:mm:ss");
    //   },
    //   width: "5%",
    //   align: "left",
    // },
    {
      Header: "college Group",
      accessor: "collegeGroupName",
      width: "5%",
      align: "left",
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (row) => (
        <div>
          <a
            style={{ cursor: "pointer" }}
            onClick={(e) => editOpen(row.row.original)}
          >
            <i class="material-icons">mode_edit</i>
          </a>
          &nbsp;
          {/* <a
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (window.confirm("Are you sure you wish to delete this item?"))
                deleteOpen(row.row.original);
            }}
          >
            <i class="material-icons">delete</i>
          </a> */}
        </div>
      ),
      align: "center",
    },
    // {
    //   Header: "Device Group Name",
    //   accessor: "dviceGroupName",
    //   width: "5%",
    //   align: "left",
    // },
  ];

  const handleCampusName = (e) => {
    setEditdata({
      ...editdata,
      campusName: e.target.value,
    });
  };

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  display="flex"
                  bgColor="info"
                  coloredShadow="info"
                  borderRadius="lg"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                >
                  <MDBox>
                    <MDTypography variant="h6" gutterBottom color="white">
                      Campus Manager
                    </MDTypography>
                  </MDBox>
                  <MDBox color="text" px={2}>
                    {localStorage.getItem("roles").includes("ROLE_ADMIN") ? (
                      <MDButton
                        style={{ color: "white" }}
                        variant="outlined"
                        type="button"
                        color="info"
                        size="small"
                        onClick={transfer}
                      >
                        Add Campus
                      </MDButton>
                    ) : null}
                  </MDBox>
                </MDBox>

                <MDBox pt={3}>
                  <MDBox
                    style={{
                      marginLeft: "25px",
                    }}
                  >
                    Select College :&nbsp;&nbsp;&nbsp;&nbsp;
                    <select
                      value={editdata.companyName || ""}
                      onChange={handleSelect}
                      style={{ width: "250px" }}
                      // defaultValue={editdata?.companyName}
                    >
                      <option value=""> Select College </option>
                      {campusDropdown.map((Devcg) => (
                        <option
                          key={Devcg.companyId}
                          id={Devcg.companyId}
                          value={Devcg.companyName}
                        >
                          {Devcg.companyName}
                        </option>
                      ))}
                    </select>
                  </MDBox>
                  {showdata ? (
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={1}
                      entriesPerPage={1}
                      showTotalEntries={1}
                      noEndBorder
                    />
                  ) : loading ? (
                    <Spinner />
                  ) : (
                    <p>No Data found</p>
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {editAddState ? "Edit Campus" : "Add Campus"} -{" "}
              {editdata?.companyName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <MDBox pt={4} pb={3} px={3}>
              <label>Campus Name:&nbsp;&nbsp;</label>
              <input
                id="Message"
                name="Message"
                type="text"
                minLength={3}
                maxLength={50}
                value={campusName}
                onChange={(e) => setCampusName(e.target.value)}
              />
              {error && (
                <span style={{ color: "red", fontSize: "15px" }}>
                  Campus Name should be Min 3 and max 50 Characters
                </span>
              )}
              <br />
            </MDBox>
            {/* <MDBox
              style={{
                marginLeft: "25px",
              }}
            >
              Select:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <select
                value={
                  editdata.companyName !== "" ? editdata.companyName : null
                }
                onChange={handleSelect}
                style={{ width: "250px" }}
                defaultValue={editdata.companyName}
              >
                <option value=""> Select College </option>
                {campusDropdown.map((Devcg) => (
                  <option id={Devcg.companyId} value={Devcg.companyName}>
                    {Devcg.companyName}
                  </option>
                ))}
              </select>
            </MDBox> */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={updateDevName}>
              {editAddState ? "Update" : "Add"}
            </Button>
          </Modal.Footer>
        </Modal>
      </DashboardLayout>
    </div>
  );
}

export default CampusManager;
