import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import App from "App";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import axios from "axios";
import DropdownProvider from "shared/ContextStore/DropdownProvider";

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    const companyId = localStorage.getItem("companyId")
      ? localStorage.getItem("companyId")
      : null;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.companyId = companyId;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
ReactDOM.render(
  <HashRouter>
    <MaterialUIControllerProvider>
      <DropdownProvider>
        <App />
      </DropdownProvider>
    </MaterialUIControllerProvider>
  </HashRouter>,
  document.getElementById("root")
);
