import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { baseUrl, baseUrladmin } from "../../../assets/js/config/config.js";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "../style.css";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

function AddDevice() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [rows, setPosts] = useState([]);
  const AccessToken = localStorage.getItem("accessToken");
  const fname = localStorage.getItem("firstName");
  const [destcmpid, setdestcmpid] = useState();
  const [devicegroup, setdeviceGroup] = useState();
  const [Deviceclassgroups, setDeviceclassgroups] = useState([]);
  const [campusDropdown, setCampusDropdown] = useState([]);
  const [campusId, setCampusId] = useState();
  const navigate = useNavigate();
  const [companytypes, setCompanytypes] = useState([]);
  const [Check, setCheck] = useState(true);

  useEffect(() => {
    fetch(baseUrladmin + "company/getAllCompaniesForAdmin", {
      headers: {
        Authorization: `Bearer ${AccessToken}`,
        "Content-type": "application/json; charset=UTF-8",
        companyId: localStorage.getItem("companyId"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == -1) {
          setCompanytypes([]);
        } else {
          setCompanytypes(data.data);
        }
        setdestcmpid(data.data[0]?.companyId);
      })
      .catch((err) => {
        console.log(err.message);
      });
    fetch(baseUrladmin + "deviceclassgroup/findAllDeviceClassGroups", {
      headers: {
        Authorization: `Bearer ${AccessToken}`,
        "Content-type": "application/json; charset=UTF-8",
        companyId: localStorage.getItem("companyId"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setDeviceclassgroups(data.data);
        setdeviceGroup(data.data[0]?.deviceClassGroupId);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  useEffect(() => {
    fetch(baseUrladmin + "collegegroup/getCollegeGroupsByCollegeId", {
      headers: {
        Authorization: `Bearer ${AccessToken}`,
        "Content-type": "application/json; charset=UTF-8",
        companyId: localStorage.getItem("companyId"),
      },
      method: "POST",
      body: JSON.stringify({ companyId: destcmpid || null }),
    })
      .then((response) => response.json())
      .then((data) => {
        setCampusDropdown(data?.data);
        setCampusDropdown(data?.data);
        const campusData = data?.data[0];
        setEditdata({
          ...editdata,
          collegeGroupId: campusData?.collegeGroupId,
          collegeGroupName: campusData?.campusGroupName,
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [destcmpid]);
  const handlSelect = (e) => {
    setdestcmpid(e.target.value);
    const { value } = event.target;
    if (campusDropdown) {
      const campusData = campusDropdown[0];
      if (campusData) {
        setCampusId(campusData.companyId);
      } else {
        // Handle the case where campusDropdown is an empty array
        console.log("campusDropdown is an empty array.");
      }
    } else {
      // Handle the case where campusDropdown is null or undefined
      console.log("campusDropdown is null or undefined.");
    }
    const college = companytypes?.find((item) => item.companyName === value);
    sessionStorage.setItem(
      "college",
      JSON.stringify({
        companyId: college?.companyId,
        companyName: college?.companyName,
      })
    );
  };
  const handleCampusSelect = (e) => {
    setCampusId(e.target.value);
  };

  const handleSelect = (e) => {
    setdeviceGroup(e.target.value);
  };
  const onSubmit = (data) => {
    console.log(data, "data");
    const bodyFormData = {
      active: Check ? Check : false,
      companyId: destcmpid
        ? destcmpid
        : companytypes[0]?.companyId
        ? companytypes[0].companyId
        : "",
      deviceAddress: data.deviceAddress,
      collGroupId: campusId ? campusId : campusDropdown[0]?.collegeGroupId,
      deviceClassGroupId: devicegroup
        ? devicegroup
        : Deviceclassgroups[0].deviceClassGroupId,
      deviceName: data.deviceName,
      updatedAt: new Date(),
    };
    axios({
      method: "post",
      url: baseUrladmin + "device/addDevice",
      data: bodyFormData,
      config: {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
        },
      },
    })
      .then((data) => {
        if (data.data.status == 200) {
          alert("Device Added Successfully");
          navigate("/devicedata");
        } else {
          alert(data.data.message);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Add Device
              </MDTypography>
            </MDBox>
            <div className="App container fpd col-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div
                  className="row"
                  style={{
                    width: "965px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="mb-2">
                    <label htmlFor="companyName">College</label>
                    <select onChange={handlSelect} style={{ width: "294px" }}>
                      {companytypes.map((companytype) => (
                        <option value={companytype.companyId}>
                          {companytype.companyName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-2">
                    <label htmlFor="collegeGroupId">Campus</label>
                    <select
                      onChange={handleCampusSelect}
                      style={{ width: "294px" }}
                    >
                      {!campusDropdown && (
                        <option value=""> ------- No Campus ------ </option>
                      )}
                      {campusDropdown?.map((Dev) => (
                        <option value={Dev.collegeGroupId}>
                          {Dev.collegeGroupName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-2">
                    <label htmlFor="deviceClassGroupName">DeviceType</label>
                    <select onChange={handleSelect} style={{ width: "294px" }}>
                      {Deviceclassgroups.map((Devcg) => (
                        <option value={Devcg.deviceClassGroupId}>
                          {Devcg.deviceClassGroupName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div
                  className="row mt-4"
                  style={{
                    width: "965px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="mb-2">
                    <label htmlFor="deviceAddress">Device Address</label>
                    <input
                      className={`form-control ${
                        errors.deviceAddress ? "is-invalid" : ""
                      }`}
                      placeholder="deviceAddress"
                      type="text"
                      name="deviceAddress"
                      maxLength={16}
                      minLength={16}
                      {...register("deviceAddress", {
                        required: true,
                        pattern: {
                          //value: /^[0-9]+$/,
                          value: /^[0-9A-F]{16}?$/i,
                        },
                      })}
                    />
                  </div>
                  <div className="mb-2">
                    <label htmlFor="deviceName">Device Name</label>
                    <input
                      className={`form-control ${
                        errors.deviceName ? "is-invalid" : ""
                      }`}
                      placeholder="Device Name"
                      type="text"
                      name="deviceName"
                      {...register("deviceName", {
                        required: true,
                        minLength: 3,
                        maxLength: 50,
                      })}
                    />
                  </div>
                  <div className="mb-2">
                    <input
                      type="checkbox"
                      id="active"
                      name="active"
                      checked={Check}
                      onChange={(e) => setCheck(e.target.checked)}
                    />
                    <label style={{ paddingLeft: "10px" }} for="active">
                      Is Active
                    </label>
                  </div>
                </div>
                <div className="mb-3">
                  <MDButton
                    variant="outlined"
                    type="submit"
                    color="info"
                    size="small"
                  >
                    Add
                  </MDButton>
                </div>
              </form>
            </div>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default AddDevice;
