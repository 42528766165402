// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { useState, useEffect } from "react";
// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
import {
  baseUrl,
  baseUrladmin,
  baseUrladminV2,
} from "../../assets/js/config/config.js";
// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import Spinner from "../tables/spinner";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Icon from "@mui/material/Icon";
import { useLocation } from "react-router-dom";

function Devicedata() {
  const { sales, tasks } = reportsLineChartData;
  const AccessToken = localStorage.getItem("accessToken");
  //const companyId = localStorage.getItem("companyId");
  const [rows, setPosts] = useState([]);
  const [editdata, setEditdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showdata, setShowdata] = useState(false);
  const navigate = useNavigate();
  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [Deviceclassgroups, setDeviceclassgroups] = useState([]);
  const [deviceName, updateDeviceName] = useState("");
  const [companytypes, setCompanytypes] = useState([]);
  const [deviceGroup, setdeviceGroup] = useState("");
  const [cmptype, setcmptype] = useState("");
  const { state } = useLocation();
  const [campusDropdown, setCampusDropdown] = useState([]);
  const [destcmpid, setdestcmpid] = useState();
  const [campusId, setCampusId] = useState();

  useEffect(() => {
    fetch(baseUrladmin + "company/getAllCollegesUnderMe", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${AccessToken}`,
        "Content-type": "application/json; charset=UTF-8",
        companyId: localStorage.getItem("companyId"),
      },
      body: JSON.stringify({
        companyId: "",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == -1) {
          setCompanytypes([]);
        } else {
          setCompanytypes(data.data);
        }
        setdestcmpid(data.data[0]?.companyId);
      })
      .catch((err) => {
        console.log(err.message);
      });

    fetch(baseUrladmin + "deviceclassgroup/findAllDeviceClassGroups", {
      headers: {
        Authorization: `Bearer ${AccessToken}`,
        "Content-type": "application/json; charset=UTF-8",
        companyId: localStorage.getItem("companyId"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setDeviceclassgroups(data.data);
        const roles = localStorage.getItem("roles");
        if (data.data) {
          if (roles === "ROLE_COLLEGEGROUP" || roles === "ROLE_COLLEGE") {
            const campusData = data.data?.find(
              (item) => item?.deviceClassGroupName === "POS"
            );
            setdeviceGroup(campusData?.deviceClassGroupId);
          }
        }
      })

      .catch((err) => {
        console.log(err.message);
      });
    serachData(); // here
  }, []);

  useEffect(() => {
    fetch(baseUrladmin + "collegegroup/getCollegeGroupsByCollegeId", {
      headers: {
        Authorization: `Bearer ${AccessToken}`,
        "Content-type": "application/json; charset=UTF-8",
        companyId: localStorage.getItem("companyId"),
      },
      method: "POST",
      body: JSON.stringify({ companyId: destcmpid === "all" ? "" : destcmpid }),
    })
      .then((response) => response.json())
      .then((data) => {
        setCampusDropdown(data?.data);
        const campusData = data?.data[0];
        // setEditdata({
        //   ...editdata,
        //   collegeGroupId: campusData?.collegeGroupId,
        //   collegeGroupName: campusData?.campusGroupName,
        // });
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [destcmpid]);

  let ddnvalue;
  let ddndisable;
  const roles = localStorage.getItem("roles");
  if (roles) {
    if (roles.includes("ROLE_COLLEGEGROUP")) {
      ddnvalue = "d41d8cd9-8f00-b204-e980-0998ecf8427e";
      ddndisable = true;
    } else if (roles.includes("ROLE_COLLEGE")) {
      ddnvalue = "d41d8cd9-8f00-b204-e980-0998ecf8427e";
      ddndisable = true;
    }
  }

  const serachData = () => {
    const bodyFormData = {
      companyId: destcmpid || "",
      campusId: campusDropdown?.length > 0 ? editdata.collegeGroupId : "",
      deviceClassGroupId: ddnvalue || deviceGroup,
    };
    axios({
      method: "post",
      url: baseUrladminV2 + "device/getAllDevicesByCollegeId",
      data: bodyFormData,
      config: {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${AccessToken}`,
          "Content-Type": "application/json; charset=utf-8",
          companyId: localStorage.getItem("companyId"),
        },
      },
    })
      .then((data) => {
        if (data.data.status == 200) {
          setShowdata(true);
          setPosts(data.data.data);
          setLoading(false);
        } else {
          setLoading(false);
          setShowdata(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const handleRowClicked = (row) => {
    navigate("/downlinkdata", { state: row });
  };
  const handleSelect = (e) => {
    setdeviceGroup(e.target.value);
  };
  const handlSelect = (e) => {
    setdestcmpid(e.target.value);
    setcmptype(e.target.value);
    const { value } = event.target;
    if (cmptype === "") {
      setEditdata({
        ...editdata,
        collegeGroupId: "",
        collegeGroupName: "",
      });
    }
    const college = companytypes?.find((item) => item.companyName === value);
    sessionStorage.setItem(
      "college",
      JSON.stringify({
        companyId: college?.companyId,
        companyName: college?.companyName,
      })
    );
  };
  const updateDevName = (row) => {
    const bodyFormData = {
      deviceId: editdata.deviceId,
      deviceName: deviceName,
    };
    axios({
      method: "post",
      url: baseUrladmin + "device/updateDeviceName",
      data: bodyFormData,
      config: {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
        },
      },
    })
      .then((response) => response)
      .then((data) => {
        if (data.data.status == 200) {
          alert("Success");
          setShow(false);
        } else {
          alert("failer");
          setShow(false);
        }
      })
      .catch((err) => {
        alert("Please Enter Device Name Properly");
        setShow(true);
      });
  };
  const transfer = (e) => {
    navigate("/adddevice");
  };
  const editOpen = (row) => {
    navigate("/editDevice", { state: row });
  };
  const deleteOpen = (row) => {
    const bodyFormData = row;
    axios({
      method: "delete",
      url: baseUrladmin + "device/deleteDevice",
      data: bodyFormData,
      config: {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
        },
      },
    })
      .then((data) => {
        if (data.data.status == 200) {
          alert("Device Deleted Successfully");
          window.location.reload(false);
        } else {
          alert(data.data.message);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const datediff = (first) => {
    const diffDuration = moment(new Date()).diff(moment(first), "hours");
    return diffDuration;
  };
  const columns = [
    {
      Header: "Device Name",
      accessor: "deviceName",
      width: "5%",
      align: "left",
    },
    {
      Header: "Device Address",
      accessor: "deviceAddress",
      width: "5%",
      align: "left",
    },
    {
      Header: "Balance Amount",
      accessor: "balanceAmount",
      width: "5%",
      align: "left",
    },
    {
      Header: "Status",
      id: "startTime",
      Cell: (row) =>
        datediff(row.row.original.updatedAt) < 24 ? (
          <span class="material-icons" style={{ color: "green" }}>
            brightness_1
          </span>
        ) : (
          <span class="material-icons" style={{ color: "red" }}>
            brightness_1
          </span>
        ),
      align: "center",
    },
    {
      Header: "Recharge",
      Cell: (row) =>
        row.row.original.deviceClassGroupName == "POS" ? (
          <div>
            <MDButton
              type="submit"
              variant="gradient"
              color="info"
              onClick={(e) => handleRowClicked(row.row.original)}
            >
              Recharge
            </MDButton>
          </div>
        ) : (
          ""
        ),
      align: "center",
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (row) => (
        <div>
          <a
            style={{ cursor: "pointer" }}
            onClick={(e) => editOpen(row.row.original)}
          >
            <i class="material-icons">mode_edit</i>
          </a>
          &nbsp;
          <a
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (window.confirm("Are you sure you wish to delete this item?"))
                deleteOpen(row.row.original);
            }}
          >
            <i class="material-icons">delete</i>
          </a>
        </div>
      ),
      align: "center",
    },
    {
      Header: "company Name",
      accessor: "companyName",
      width: "10%",
      align: "left",
    },
    {
      Header: "Last Seen",
      accessor: (d) => {
        return moment(d.updatedAt).local().format("DD-MM-YYYY HH:mm:ss");
      },
      width: "5%",
      align: "left",
    },
    {
      Header: "Device Type Name",
      accessor: "deviceClassGroupName",
      width: "5%",
      align: "left",
    },
    {
      Header: "Device Group Name",
      accessor: "dviceGroupName",
      width: "5%",
      align: "left",
    },
  ];

  const handleCampusSelect = (e) => {
    console.log(e, "check");
    // setCampusId(e.target.value);
    const { value } = event.target;
    const campusData = campusDropdown?.find(
      (item) => item.collegeGroupName === value
    );
    setEditdata({
      ...editdata,
      collegeGroupId: value || campusData?.collegeGroupId,
      collegeGroupName: campusData?.campusGroupName,
    });
  };

  return (
    <div>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  display="flex"
                  bgColor="info"
                  coloredShadow="info"
                  borderRadius="lg"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                >
                  <MDBox>
                    <MDTypography variant="h6" gutterBottom color="white">
                      Devices
                    </MDTypography>
                  </MDBox>
                  <MDBox color="text" px={2}>
                    {localStorage.getItem("roles").includes("ROLE_ADMIN") ? (
                      <MDButton
                        style={{ color: "white" }}
                        variant="outlined"
                        type="button"
                        color="info"
                        size="small"
                        onClick={transfer}
                      >
                        Add Device
                      </MDButton>
                    ) : null}
                  </MDBox>
                </MDBox>
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: "0px", marginLeft: "0px" }}
                >
                  <MDBox
                    mb={2}
                    style={{
                      marginTop: "7px",
                      marginLeft: "15px",
                      fontSize: "15px",
                    }}
                  >
                    Colleges <br />
                    <select onChange={handlSelect} value={cmptype}>
                      <option value="">
                        {" "}
                        ------------- All -------------{" "}
                      </option>
                      {companytypes.map((companytype) => (
                        <option value={companytype.companyId}>
                          {companytype.companyName}
                        </option>
                      ))}
                    </select>
                  </MDBox>
                  <MDBox
                    mb={2}
                    style={{
                      marginTop: "7px",
                      marginLeft: "15px",
                      fontSize: "15px",
                    }}
                  >
                    Campus <br />
                    <select
                      onChange={handleCampusSelect}
                      style={{ width: "294px" }}
                      // value={destcmpid}
                    >
                      {!campusDropdown ? (
                        <option value=""> ------- No Campus ------ </option>
                      ) : (
                        <option value=""> ------- All ------ </option>
                      )}

                      {campusDropdown?.map((Dev) => (
                        <option value={Dev.collegeGroupId}>
                          {Dev.collegeGroupName}
                        </option>
                      ))}
                    </select>
                  </MDBox>
                  <MDBox
                    mb={2}
                    style={{
                      marginTop: "7px",
                      marginLeft: "45px",
                      fontSize: "15px",
                    }}
                  >
                    Phone Type <br />
                    <select
                      value={ddnvalue}
                      onChange={handleSelect}
                      disabled={ddndisable}
                    >
                      <option value="">
                        {" "}
                        ------------- All -------------{" "}
                      </option>
                      {Deviceclassgroups.map((Devcg) => (
                        <option value={Devcg.deviceClassGroupId}>
                          {Devcg.deviceClassGroupName}
                        </option>
                      ))}
                    </select>
                  </MDBox>
                  <MDBox
                    mb={2}
                    style={{
                      marginTop: "0px",
                      marginLeft: "40px",
                      fontSize: "15px",
                    }}
                  >
                    <br />
                    <MDButton
                      variant="outlined"
                      onClick={serachData}
                      color="info"
                      size="small"
                    >
                      Search
                    </MDButton>
                  </MDBox>
                </Grid>
                <MDBox pt={3}>
                  {showdata ? (
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={1}
                      entriesPerPage={1}
                      showTotalEntries={1}
                      noEndBorder
                    />
                  ) : loading ? (
                    <Spinner />
                  ) : (
                    <p>No Data found</p>
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <MDBox pt={4} pb={3} px={3}>
              <label>Device Address:&nbsp;&nbsp;</label>
              {editdata.deviceAddress}
              <label>
                Amount&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
              </label>
              {editdata.balanceAmount}
              <br />
              <label>
                Device Name
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:{" "}
              </label>
              <input
                id="Message"
                name="Message"
                type="text"
                minLength={7}
                onChange={(e) => updateDeviceName(e.target.value)}
              />
              <br />
            </MDBox>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={updateDevName}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </DashboardLayout>
    </div>
  );
}

export default Devicedata;
