import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import MDButton from "components/MDButton";
import { baseUrl, baseUrladmin } from "../../assets/js/config/config.js";
import moment from "moment";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

function formatDate(date) {
  return [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join(
    "/"
  );
}

function Messages() {
  const AccessToken = localStorage.getItem("accessToken");
  const companyId = localStorage.getItem("companyId");
  const [messages, setMessages] = useState([]);

  var date = new Date();
  date.setDate(date.getDate() - 7);
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(new Date());
  const [isdata, setIsdata] = useState(true);
  const [Deviceclassgroups, setDeviceclassgroups] = useState([]);
  const [companytypes, setCompanytypes] = useState([]);
  const [deviceGroup, setdeviceGroup] = useState([]);
  const [cmptype, setcmptype] = useState(null);

  useEffect(() => {
    fetch(baseUrladmin + "company/getListOfCompaniesUnderId", {
      headers: {
        Authorization: `Bearer ${AccessToken}`,
        "Content-type": "application/json; charset=UTF-8",
        "companyId": localStorage.getItem("companyId"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == -1) {
          setCompanytypes([]);
        }
        else {
          setCompanytypes(data.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });

    fetch(baseUrladmin + "deviceclassgroup/findAllDeviceClassGroups", {
      headers: {
        Authorization: `Bearer ${AccessToken}`,
        "Content-type": "application/json; charset=UTF-8",
        "companyId": localStorage.getItem("companyId"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setDeviceclassgroups(data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
    serachData();
  }, []);
  const handleSelect = (e) => {
    setdeviceGroup(e.target.value);
  }
  const handlSelect = (e) => {
    setcmptype(e.target.value);
  }
  const serachData = () => {
    const bodyFormData = {
      //companyId: companyId,
      fromDate: formatDate(startDate),
      toDate: formatDate(endDate),
      //deviceClassGroupId: deviceGroup,
      companyId: cmptype
    };
    axios({
      method: "post",
      url: baseUrladmin + "phoneStatus/getPhoneStatusByCompanyIdAndDates",
      data: bodyFormData,
      config: {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${AccessToken}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      },
    })
      .then((data) => {
        if (data.data.status == -1) {
          setIsdata(true);
        }
        else {
          setIsdata(false);
          setMessages(data.data.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const rows = messages;
  const columns = [
    { Header: "Transaction Id", accessor: "transactionId", width: "10%", align: "left" },
    { Header: "Device Name", accessor: "deviceName", width: "10%", align: "left" },
    { Header: "Device Address", accessor: "deviceAddress", width: "10%", align: "left" },
    { Header: "Duration Amount", accessor: "duration", width: "10%", align: "left" },
    {
      Header: "StatusUpdatedAt", accessor: d => {
        return moment(d.statusUpdatedAt)
          .local()
          .format("DD-MM-YYYY hh:mm:ss a")
      }, width: "10%", align: "left"
    },
  ];
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  PhoneStatus History
                </MDTypography>
              </MDBox>
              <Grid container spacing={3} style={{ marginTop: "0px", marginLeft: "0px" }}>
                <MDBox mb={2} style={{ marginTop: "7px", marginLeft: "12px", fontSize: "15px" }}>
                  From Date&nbsp;
                  <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                </MDBox>
                <MDBox mb={2} style={{ marginTop: "7px", marginLeft: "12px", fontSize: "15px" }}>
                  To Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                </MDBox>
                <MDBox mb={2} style={{ marginTop: "7px", marginLeft: "7px", fontSize: "15px" }}>
                  Colleges <br />
                  <select onChange={handlSelect}>
                    <option value="all"> ------------- All ------------- </option>
                    {companytypes.map((companytype) => (
                      <option value={companytype.companyId}>{companytype.companyName}</option>
                    ))}
                  </select>
                </MDBox>
                {/* <MDBox mb={2} style={{ marginTop: "7px", marginLeft: "7px", fontSize: "15px" }}>
                  Device Class Group <br />
                  <select onChange={handleSelect}>
                    <option value="all"> ------------- All ------------- </option>
                    {Deviceclassgroups.map((Devcg) => (
                      <option value={Devcg.deviceClassGroupId}>{Devcg.deviceClassGroupName}</option>
                    ))}
                  </select>
                </MDBox> */}

                <MDBox mb={2} style={{ marginTop: "7px", marginLeft: "12px", fontSize: "15px" }}>
                  <br />
                  <MDButton variant="outlined" onClick={serachData} color="info" size="small">
                    Search
                  </MDButton>
                </MDBox>
              </Grid>
              <MDBox pt={3}>
                {!isdata ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={1}
                    entriesPerPage={1}
                    showTotalEntries={1}
                    noEndBorder
                  />
                ) : (
                  <MDBox mb={2} style={{ marginTop: "7px", marginLeft: "12px", fontSize: "15px" }}>
                    No Data Found
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Messages;
