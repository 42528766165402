// import React, { useEffect, useState } from "react";
// import { Pie, Bar } from "react-chartjs-2";
// import {
//   Chart as ChartJS,
//   ArcElement,
//   Tooltip,
//   Legend,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
// } from "chart.js";
// import axios from "axios";
// import dayjs from "dayjs"; // Import dayjs for date manipulation
// import isBetween from "dayjs/plugin/isBetween"; // Import isBetween plugin
// import { baseUrladmin } from "assets/js/config/config";

// dayjs.extend(isBetween); // Extend dayjs with the isBetween plugin

// ChartJS.register(
//   ArcElement,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend
// );

// const PieChart = () => {
//   const [messages, setMessages] = useState();
//   const [selectedLocation, setSelectedLocation] = useState("ALL");
//   const [filteredMessages, setFilteredMessages] = useState([]);
//   const companyId = localStorage.getItem("companyId");
//   const AccessToken = localStorage.getItem("accessToken");

//   const serachData = () => {
//     // Calculate fromDate as 3 days back and toDate as the present day
//     const fromDate = dayjs().subtract(2, "day").format("DD/MM/YYYY");
//     const toDate = dayjs().format("DD/MM/YYYY");

//     const bodyFormData = {
//       companyId: companyId,
//       fromDate: fromDate,
//       toDate: toDate,
//     };
//     axios({
//       method: "post",
//       url: baseUrladmin + "message/getMessagesByDates",
//       data: bodyFormData,
//       config: {
//         headers: {
//           Accept: "application/json",
//           Authorization: `Bearer ${AccessToken}`,
//           "Content-Type": "application/json; charset=utf-8",
//           companyId: localStorage.getItem("companyId"),
//         },
//       },
//     })
//       .then((data) => {
//         if (data?.data?.status == -1) {
//           // setIsdata(true);
//         } else {
//           // setIsdata(false);
//           setMessages(data?.data?.data || []);
//         }
//       })
//       .catch((err) => {
//         console.log(err.message);
//       });
//   };

//   useEffect(() => {
//     serachData();
//   }, []);

//   // Extract locations from messages
//   const getLocations = () => {
//     const locations =
//       messages?.length > 0 &&
//       messages?.map((msg) => {
//         const match = msg?.message?.match(/Loc:([^\r]+)/);
//         return match ? match[1] : "Unknown";
//       });
//     return messages?.length > 0 ? ["ALL", ...new Set(locations)] : [];
//   };

//   // Filter messages by selected location
//   useEffect(() => {
//     if (selectedLocation === "ALL") {
//       setFilteredMessages(messages);
//     } else {
//       const filtered = messages.filter((msg) => {
//         const match = msg.message.match(/Loc:([^\r]+)/);
//         return match && match[1] === selectedLocation;
//       });
//       setFilteredMessages(filtered);
//     }
//   }, [selectedLocation, messages]);

//   // Dynamically calculate data for charts
//   const calculateChartData = () => {
//     const today = dayjs().startOf("day");
//     const threeDaysAgo = today.subtract(3, "day");

//     const reportedToday =
//       filteredMessages?.length > 0 &&
//       filteredMessages?.filter((msg) => {
//         const createdAt = dayjs(msg.createdAt);
//         return createdAt.isSame(today, "day");
//       }).length;

//     const reportedLast3Days =
//       filteredMessages?.length > 0 &&
//       filteredMessages.filter((msg) => {
//         const createdAt = dayjs(msg.createdAt);
//         return createdAt.isBetween(threeDaysAgo, today, null, "[)");
//       }).length;

//     const totalDevices =
//       filteredMessages?.length > 0 && filteredMessages?.length; // Total number of devices reported

//     const notReportedLast3Days =
//       totalDevices - (reportedToday + reportedLast3Days);

//     return {
//       reportedToday,
//       reportedLast3Days,
//       notReportedLast3Days,
//     };
//   };

//   // Get dynamic chart data
//   const chartData = calculateChartData();

//   const pieData = {
//     labels: [
//       "Reported from Last 3 Days",
//       "Reported for Today",
//       "Not Reported from Last 3 Days",
//     ],
//     datasets: [
//       {
//         label: "# of Reports",
//         data: [
//           chartData.reportedLast3Days,
//           chartData.reportedToday,
//           chartData.notReportedLast3Days,
//         ],
//         backgroundColor: ["#905ebf", "#5e9fbf", "#d1a93b"],
//         borderColor: [
//           "rgba(54, 162, 235, 1)",
//           "rgba(75, 192, 192, 1)",
//           "rgba(255, 99, 132, 1)",
//         ],
//         borderWidth: 1,
//       },
//     ],
//   };

//   const barData = {
//     labels: [
//       "Overall Reported from Last 3 Days",
//       "Overall Reported for Today",
//       "Overall Not Reported from Last 3 Days",
//     ],
//     datasets: [
//       {
//         label: "Number of Reports",
//         data: [
//           chartData.reportedLast3Days,
//           chartData.reportedToday,
//           chartData.notReportedLast3Days,
//         ],
//         backgroundColor: ["#905ebf", "#5e9fbf", "#d1a93b"],
//         borderColor: [
//           "rgba(54, 162, 235, 1)",
//           "rgba(75, 192, 192, 1)",
//           "rgba(255, 99, 132, 1)",
//         ],
//         borderWidth: 1,
//       },
//     ],
//   };

//   // Options for the bar chart
//   const barOptions = {
//     indexAxis: "y", // Horizontal bar chart
//     responsive: true,
//     plugins: {
//       legend: {
//         display: false, // Hide legend in the bar chart
//       },
//       title: {
//         display: false,
//         text: "Overall Reported Status",
//       },
//     },
//     scales: {
//       x: {
//         title: {
//           display: true,
//           text: "Number of Reports",
//         },
//         position: "top", // Move x-axis labels to the top
//       },
//       y: {
//         title: {
//           display: true,
//           text: "Categories",
//         },
//       },
//     },
//   };

//   return (
//     <div>
//       <h2>Statistics</h2>
//       <div
//         style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
//       >
//         <h6 style={{ marginRight: "10px" }}>Select Location</h6>
//         <select
//           value={selectedLocation}
//           onChange={(e) => setSelectedLocation(e.target.value)}
//         >
//           {getLocations()?.map((loc, idx) => (
//             <option key={idx} value={loc}>
//               {loc}
//             </option>
//           ))}
//         </select>
//       </div>

//       <div
//         style={{
//           display: "flex",
//           flexDirection: "row",
//           justifyContent: "space-between",
//         }}
//       >
//         <div style={{ height: "100%", width: "50%" }}>
//           <h2>Overall Reported Status</h2>
//           <Bar data={barData} options={barOptions} />
//         </div>
//         <div style={{ height: "100%", marginRight: "10%" }}>
//           <h2>Overall Distribution</h2>
//           <Pie data={pieData} options={{ responsive: true }} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PieChart;

import React, { useEffect, useState } from "react";
import { Pie, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import axios from "axios";
import dayjs from "dayjs"; // Import dayjs for date manipulation
import isBetween from "dayjs/plugin/isBetween"; // Import isBetween plugin
import { baseUrladmin } from "assets/js/config/config";
import { notification, Table } from "antd";

dayjs.extend(isBetween); // Extend dayjs with the isBetween plugin

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const PieChart = () => {
  const [messages, setMessages] = useState([]);
  const companyId = localStorage.getItem("companyId");
  const AccessToken = localStorage.getItem("accessToken");
  const [upToday, setUpToday] = useState(0);
  const [upLastThreeDays, setUpLastThreeDays] = useState(0);
  const [downLastThreeDays, setDownLastThreeDays] = useState(0);
  const [cbZeroDevices, setCbZeroDevices] = useState([]); // For storing devices with cb: 0

  const serachData = () => {
    // Calculate fromDate as 3 days back and toDate as the present day
    const fromDate = dayjs().subtract(3, "day").format("DD/MM/YYYY");
    const toDate = dayjs().format("DD/MM/YYYY");

    const bodyFormData = {
      companyId: companyId,
      fromDate: fromDate,
      toDate: toDate,
    };

    axios({
      method: "post",
      url: baseUrladmin + "message/getMessagesByDates",
      data: bodyFormData,
      config: {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${AccessToken}`,
          "Content-Type": "application/json; charset=utf-8",
          companyId: localStorage.getItem("companyId"),
        },
      },
    })
      .then((data) => {
        if (data?.data?.status === -1) {
          // Handle the case when no data is found or show an appropriate message
        } else {
          setMessages(data?.data?.data || []);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    serachData();
  }, []);

  useEffect(() => {
    let upTodayCount = 0;
    let upLastThreeDaysCount = 0;
    let downLastThreeDaysCount = 0;
    let cbZeroMap = {}; // Use a map to store device address and count of cb: 0

    const now = new Date();

    messages?.forEach((device) => {
      const deviceDate = new Date(device?.createdAt);

      // Sanitize the message string to avoid bad control characters
      const sanitizedMessage = device?.message.replace(/[\r\n]/g, ""); // Remove carriage returns or newlines

      try {
        const deviceObject = JSON.parse(sanitizedMessage);
        const deviceStatus = deviceObject?.status;

        // Count how many devices are up and down in the last 3 days
        if (deviceStatus === "up") {
          upLastThreeDaysCount++;
          // If the device was reported today, count it separately
          if (
            deviceDate.getDate() === now.getDate() &&
            deviceDate.getMonth() === now.getMonth() &&
            deviceDate.getFullYear() === now.getFullYear()
          ) {
            upTodayCount++;
          }
        } else {
          downLastThreeDaysCount++;
        }
        // Check for 'cb: 0' in the message and count it
        if (deviceObject?.cb === 0) {
          if (cbZeroMap[device.deviceAddress]) {
            cbZeroMap[device.deviceAddress]++;
          } else {
            cbZeroMap[device.deviceAddress] = 1;
          }
        }
      } catch (error) {
        console.error("Error parsing message JSON:", error);
      }
    });

    // Convert cbZeroMap to an array for table rendering
    const cbZeroArray = Object.keys(cbZeroMap).map((deviceAddress, index) => ({
      serialNumber: index + 1,
      deviceAddress,
      cbZeroCount: cbZeroMap[deviceAddress],
    }));

    // Set the counts
    setUpToday(upTodayCount);
    setUpLastThreeDays(upLastThreeDaysCount);
    setDownLastThreeDays(downLastThreeDaysCount);
    setCbZeroDevices(cbZeroArray); // Set the cb: 0 devices in state
  }, [messages]);

  // Calculate chart data based on the presence of '/ul' in 'topic'
  const calculateChartData = () => {
    const today = dayjs().startOf("day");
    const threeDaysAgo = today.subtract(3, "day");

    // Filter messages reported today
    const reportedToday = messages?.filter((msg) => {
      const createdAt = dayjs(msg.createdAt);
      return (
        createdAt.isSame(today, "day") &&
        msg.topicName &&
        msg.topicName.trim().endsWith("/ul")
      );
    }).length;

    // Filter messages reported in the last 3 days
    const reportedLast3Days = messages?.filter((msg) => {
      const createdAt = dayjs(msg.createdAt);
      return (
        createdAt.isBetween(threeDaysAgo, today, null, "[)") &&
        msg.topicName &&
        msg.topicName.trim().endsWith("/ul")
      );
    }).length;

    // Count messages not reported in the last 3 days (missing '/ul')
    const notReportedLast3Days = messages?.filter((msg) => {
      const createdAt = dayjs(msg.createdAt);
      return (
        createdAt.isBetween(threeDaysAgo, today, null, "[)") &&
        (!msg.topicName || !msg.topicName.trim().endsWith("/ul"))
      );
    }).length;

    return {
      reportedToday,
      reportedLast3Days,
      notReportedLast3Days,
    };
  };

  // Get dynamic chart data
  const chartData = calculateChartData();

  const pieData = {
    labels: [
      "Reported from Last 3 Days",
      "Reported for Today",
      "Not Reported from Last 3 Days", // Updated label
    ],
    datasets: [
      {
        label: "# of Reports",
        data: [
          chartData.reportedLast3Days,
          chartData.reportedToday,
          chartData.notReportedLast3Days, // Updated to only show missing '/ul'
        ],
        backgroundColor: ["#905ebf", "#5e9fbf", "#d1a93b"],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(255, 99, 132, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const barData = {
    labels: [
      "Overall Reported from Last 3 Days",
      "Overall Reported for Today",
      "Overall Not Reported from Last 3 Days", // Updated label
    ],
    datasets: [
      {
        label: "Number of Reports",
        data: [
          chartData.reportedLast3Days,
          chartData.reportedToday,
          chartData.notReportedLast3Days, // Updated to only show missing '/ul'
        ],
        backgroundColor: ["#905ebf", "#5e9fbf", "#d1a93b"],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(255, 99, 132, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  // Options for the bar chart
  const barOptions = {
    indexAxis: "y", // Horizontal bar chart
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide legend in the bar chart
      },
      title: {
        display: false,
        text: "Overall Reported Status",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Number of Reports",
        },
        position: "top", // Move x-axis labels to the top
      },
      y: {
        title: {
          display: true,
          text: "Categories",
        },
      },
    },
  };

  // Extract locations from messages
  const getLocations = () => {
    const locations =
      messages?.length > 0 &&
      messages?.map((msg) => {
        const match = msg?.message?.match(/Loc:([^\r]+)/);
        return match ? match[1] : "Unknown";
      });
    return messages?.length > 0 ? ["ALL", ...new Set(locations)] : [];
  };

  // Group data by location and calculate report statistics
  const calculateGroupedData = () => {
    const today = dayjs().startOf("day");
    const threeDaysAgo = today.subtract(3, "day");

    const locations = {}; // To store data grouped by location

    messages.forEach((msg) => {
      const createdAt = dayjs(msg.createdAt);
      const locMatch = msg?.message?.match(/Loc:([^\r]+)/);
      const location = locMatch ? locMatch[1] : "Unknown";

      if (!locations[location]) {
        locations[location] = {
          reportedToday: 0,
          reportedLast3Days: 0,
          notReportedLast3Days: 0,
        };
      }

      // Reported Today
      if (
        createdAt.isSame(today, "day") &&
        msg.topicName &&
        msg.topicName.trim().endsWith("/ul")
      ) {
        locations[location].reportedToday++;
      }

      // Reported in Last 3 Days
      if (
        createdAt.isBetween(threeDaysAgo, today, null, "[)") &&
        msg.topicName &&
        msg.topicName.trim().endsWith("/ul")
      ) {
        locations[location].reportedLast3Days++;
      }

      // Not Reported in Last 3 Days
      if (
        createdAt.isBetween(threeDaysAgo, today, null, "[)") &&
        (!msg.topicName || !msg.topicName.trim().endsWith("/ul"))
      ) {
        locations[location].notReportedLast3Days++;
      }
    });

    return locations;
  };

  const groupedData = calculateGroupedData();
  const locationsList = getLocations(); // List of locations for the x-axis

  // Prepare the data for the Bar chart
  const reportedTodayData = locationsList.map(
    (location) => groupedData[location]?.reportedToday || 0
  );
  const reportedLast3DaysData = locationsList.map(
    (location) => groupedData[location]?.reportedLast3Days || 0
  );
  const notReportedLast3DaysData = locationsList.map(
    (location) => groupedData[location]?.notReportedLast3Days || 0
  );

  const CollegebarData = {
    labels: locationsList, // X-axis will be the locations
    datasets: [
      {
        label: "Reported Today",
        data: reportedTodayData,
        backgroundColor: "#5e9fbf",
      },
      {
        label: "Reported Last 3 Days",
        data: reportedLast3DaysData,
        backgroundColor: "#905ebf",
      },
      {
        label: "Not Reported Last 3 Days",
        data: notReportedLast3DaysData,
        backgroundColor: "#d1a93b",
      },
    ],
  };

  // Options for the bar chart
  const CollegebarOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true, // Show legend for the bar chart
      },
      title: {
        display: true,
        text: "College Reported Status",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Locations (Colleges)",
        },
        ticks: {
          maxRotation: 90, // Rotate the labels to be vertical
          minRotation: 90,
          autoSkip: false, // Ensure that no labels are skipped
        },
      },
      y: {
        title: {
          display: true,
          text: "Number of Reports",
        },
      },
    },
  };

  const statusData = {
    labels: ["Up Today", "Up Last 3 Days", "Down Last 3 Days"],
    datasets: [
      {
        label: "Device Status",
        data: [upToday, upLastThreeDays, downLastThreeDays],
        backgroundColor: ["#905ebf", "#5e9fbf", "#d1a93b"],
        borderColor: [
          "rgba(75, 192, 192, 1)", // Up today
          "rgba(54, 162, 235, 1)", // Up last 3 days
          "rgba(255, 99, 132, 1)", // Down last 3 days
        ],
        borderWidth: 1,
      },
    ],
  };

  const statusOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Device Status in Last 3 Days",
      },
    },
  };

  // Define columns for Ant Design Table
  const columns = [
    {
      title: "Serial Number",
      dataIndex: "serialNumber",
      key: "serialNumber",
    },
    {
      title: "Device Address",
      dataIndex: "deviceAddress",
      key: "deviceAddress",
    },
    {
      title: "Count of cb: 0",
      dataIndex: "cbZeroCount",
      key: "cbZeroCount",
    },
  ];

  return (
    <div style={{ gap: "50px", display: "grid" }}>
      <div style={{ height: "300px", width: "100%", marginBottom: "20px" }}>
        <h2>College wise Reported Status</h2>
        <Bar data={CollegebarData} options={CollegebarOptions} height="100%" />
      </div>
      <div
        style={{
          height: "300px",
          width: "100%",
          marginBottom: "20px",
          marginTop: "10%",
        }}
      >
        <h2>Device Address wise Status</h2>
        <Bar data={statusData} options={statusOptions} height="100%" />
      </div>
      {/* Render the table for devices with cb: 0 */}
      <div style={{ marginTop: "10%" }}>
        <h2>Devices with cb: 0</h2>
        <Table
          columns={columns}
          dataSource={cbZeroDevices} // Data source for the table
          pagination={true} // Disable pagination if not needed
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div style={{ height: "100%", width: "50%" }}>
          <h2>Overall Reported Status</h2>
          <Bar data={barData} options={barOptions} />
        </div>
        <div style={{ height: "100%", marginRight: "10%" }}>
          <h2>Overall Distribution</h2>
          <Pie data={pieData} options={{ responsive: true }} />
        </div>
      </div>
    </div>
  );
};

export default PieChart;
