import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { baseUrl, baseUrladmin } from "../../assets/js/config/config.js";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "axios";

function DevicesDevicedownlinkData() {
  const AccessToken = localStorage.getItem("accessToken");
  const [posts, setPosts] = useState([]);
  const [showgrid, setShowgrid] = useState(true);
  const [deviceOnHr, setDeviceOnHr] = useState('');
  const [deviceOnMin, setDeviceOnMin] = useState('');
  const [deviceOffHr, setDeviceOffHr] = useState('');
  const [deviceOffMin, setDeviceOffMin] = useState('');
  const [deviceAddress, setDeviceAddress]= useState('');
  const handleEdit = (row) => {
    setDeviceAddress(row.deviceAddress);
    setDeviceOnHr(row.deviceOnHr);
    setDeviceOnMin(row.deviceOnMin);
    setDeviceOffHr(row.deviceOffHr);
    setDeviceOffMin(row.deviceOffMin);
    setShowgrid(false);
  }
  const handleUpdate =() =>{
    const bodyFormData = {
      deviceAddress:deviceAddress,
      deviceOnHr: parseInt(deviceOnHr),
      deviceOnMin: parseInt(deviceOnMin),
      deviceOffHr: parseInt(deviceOffHr),
      deviceOffMin: parseInt(deviceOffMin),
      deviceMode: 0,
      deviceLoad: "L1",
      deviceStatus: "ON"
    };
    axios({
      method: "put",
      url: baseUrladmin + "deviceschedule/updateDSSByDeviceAddress",
      data: bodyFormData,
      config: {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
        },
      },
    })
      .then((response) => response)
      .then((data) => {
        if (data.data.status == 200) {
          alert("Updated successfully");
          window.location.reload(false);
        } else {
          alert("Something went wrong");
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }
  useEffect(() => {
    fetch(baseUrladmin + "deviceschedule/getAllDeviceScheduleStatus", {
      headers: {
        Authorization: `Bearer ${AccessToken}`,
        "Content-type": "application/json; charset=UTF-8",
        "companyId":  localStorage.getItem("companyId"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPosts(data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  const rows = posts;
  const columns = [
    { Header: "Device Address", accessor: "deviceAddress", width: "10%", align: "left" },
    { Header: "Device Status", accessor: "deviceStatus", width: "10%", align: "left" },
    { Header: "Device Load", accessor: "deviceLoad", width: "10%", align: "left" },
    { Header: "Device Mode", accessor: "deviceMode", width: "10%", align: "left" },
    { Header: "Device ON Hr", accessor: "deviceOnHr", width: "10%", align: "left" },
    { Header: "Device OFF Min", accessor: "deviceOnMin", width: "10%", align: "left" },
    { Header: "Device OFF Hr", accessor: "deviceOffHr", width: "10%", align: "left" },
    { Header: "Device OFF Min", accessor: "deviceOffMin", width: "10%", align: "left" },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: row => (
        <div>
       
              <MDButton type="submit" variant="gradient" color="info"  onClick={e => handleEdit(row.row.original)}>
              Edit
              </MDButton>
     
        </div>
      ),
    }
  ];
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div> {showgrid
        ?
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Device Schedule
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={1}
                    entriesPerPage={1}
                    showTotalEntries={1}
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox> :
        // <MDBox pt={6} pb={3}>
        <Grid container spacing={6}><Grid item xs={12}>
          <Card>
            <MDBox pt={4} pb={3} px={3}>
            <label>Device Address&nbsp;&nbsp;&nbsp;&nbsp;: {deviceAddress}</label>
            <br />
              <label>Device On Hour &nbsp;&nbsp;&nbsp;: </label>
              <input
                name='DeviceOnHr'
                type='text'
                value={deviceOnHr}
                onChange={e => setDeviceOnHr(e.target.value)}
              />
              <br />
              <label>Device On Minute&nbsp;:</label>
              <input
                name='DeviceOnMin'
                type='text'
                value={deviceOnMin}
                onChange={e => setDeviceOnMin(e.target.value)}
              />
              <br />
              <label>Device Off Hour &nbsp;&nbsp;&nbsp;:</label>
              <input
                name='DeviceOffHr'
                type='text'
                value={deviceOffHr}
                onChange={e => setDeviceOffHr(e.target.value)}
              />
              <br />
              <label>Device Off Minute &nbsp;:</label>
              <input
                name='DeviceOffMin'
                type='text'
                value={deviceOffMin}
                onChange={e => setDeviceOffMin(e.target.value)}
              />
              <br />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" onClick={e => handleUpdate()}>
              Update
              </MDButton>
            </MDBox>
          </Card>
        </Grid>
        </Grid>
        //</MDBox>
      }
      </div>
    </DashboardLayout>
  );
}

export default DevicesDevicedownlinkData;
