// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useState, useEffect } from "react";
import Spinner from "../tables/spinner";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import { baseUrl, baseUrladmin } from "../../assets/js/config/config.js";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

function Balancetransfer() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [rows, setPosts] = useState([]);
    const AccessToken = localStorage.getItem("accessToken");
    const fname = localStorage.getItem("firstName");
    const [destcmpid, setdestcmpid] = useState();
    const [bal, setbal] = useState();
    const navigate = useNavigate();

    const handlSelect = (e) => {
        setdestcmpid(e.target.value);
    }
    const onSubmit = (data) => {
        const bodyFormData = {
            "destiCompanyId": destcmpid ? destcmpid : rows[0].companyId,
            "scoureCompanyId": localStorage.getItem("companyId"),
            //"transactionId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            "transferNotes": data.transferNotes,
            "transferredAmount": data.amount,
            "transferredBy": fname
        };
        axios({
            method: "post",
            url: baseUrladmin + "balance/transferBalance",
            data: bodyFormData,
            config: {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json; charset=utf-8",
                },
            },
        })
            .then((data) => {
                if (data.data.status == 200) {
                    alert("Transferred Successfully");
                    //window.location.reload(false);
                    navigate("/transactionreport");
                }
                else {
                    alert(data.data.message);
                    window.location.reload(false);
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    }
    useEffect(() => {
        fetch(baseUrladmin + "dashboard/getDashboardDetails", {
            headers: {
                Authorization: `Bearer ${AccessToken}`,
                "Content-type": "application/json; charset=UTF-8",
                "companyId": localStorage.getItem("companyId"),
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setPosts(data.data.lstCompanies);
            })
            .catch((err) => {
                console.log(err.message);
            });
        fetch(baseUrladmin + "balance/getCompanyBalance", {
            headers: {
                Authorization: `Bearer ${AccessToken}`,
                "Content-type": "application/json; charset=UTF-8",
                "companyId": localStorage.getItem("companyId"),
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setbal(data.data.balance);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);
    return (
        <DashboardLayout>
            <DashboardNavbar />
            {/* <MDBox pt={6} pb={3} style={{ paddingTop: "0px" }}> */}
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Card>
                        <MDBox
                            mx={2}
                            mt={3}
                            py={3}
                            px={2}
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="info"
                        >
                            <MDTypography variant="h6" color="white">
                                Balance Transfer
                            </MDTypography>
                        </MDBox>
                        <div className='App container fpd col-6'>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='row'>
                                    <div className='mb-2'>
                                        <label htmlFor='email'>From</label>
                                        <input
                                            className='form-control'
                                            placeholder='Email'
                                            type='name'
                                            name='email'
                                            value={fname}
                                            readOnly
                                        />
                                    </div>
                                    <div className='mb-2'>
                                        <label htmlFor='email'>Balance</label>
                                        <input
                                            className='form-control'
                                            placeholder='Balance'
                                            type='name'
                                            name='balance'
                                            value={bal}
                                            readOnly
                                        />
                                    </div>
                                    <div className='mb-2'>
                                        <label htmlFor='password'>To</label>
                                        <select onChange={handlSelect} className='form-control' style={{ WebkitAppearance: "auto" }}>
                                            {rows.map((companytype) => (
                                                <option value={companytype.companyId}>{companytype.companyName}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='mb-3'>
                                        <label htmlFor='confirmpassword'>Amount</label>
                                        <input
                                            className={`form-control ${errors.amount ? 'is-invalid' : ''}`}
                                            placeholder='Amount'
                                            type='number'
                                            name='amount'
                                            {...register("amount", { required: true, min: 100, max: 500000 })}
                                        />
                                    </div>
                                    <div className='mb-3'>
                                        <label htmlFor='confirmpassword'>Notes</label>
                                        <input
                                            className={`form-control ${errors.notes ? 'is-invalid' : ''}`}
                                            placeholder='Notes'
                                            type='text'
                                            name='notes'
                                            {...register("notes", { required: true })}
                                        />
                                    </div>
                                </div>
                                <div className='mb-2'>
                                </div>
                                <div className='mb-3'>
                                </div>
                                <div className='mb-2'>
                                    <MDButton variant="outlined" type="submit" color="info" size="small">
                                        Transfer
                                    </MDButton>
                                </div>
                            </form>

                        </div>
                        <div className='mb-3'>
                        </div>
                    </Card>
                </Grid>
            </Grid>
            {/* </MDBox> */}
        </DashboardLayout>
    );
}

export default Balancetransfer;
