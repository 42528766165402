import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { baseUrl, baseUrladmin } from "../../../assets/js/config/config.js";
import MDButton from "components/MDButton";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Spinner from "../../tables/spinner";
function Customers() {

    const roles = localStorage.getItem("roles");
    let heading;
    if (roles) {
        if (roles.includes("ROLE_COLLEGEGROUP")) {
            heading = "Colleges"
        } else {
            heading = "College Group"
        }
    }
    const AccessToken = localStorage.getItem("accessToken");
    const [posts, setCompanytypes] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [showdata, setShowdata] = useState(false);

    const transfer = (e) => {
        navigate("/addCustomer");
    }
    const editOpen = row => {
        navigate("/editCustomer", { state: row });
    }
    const deleteOpen = (row) => {
        const bodyFormData = row
        axios({
            method: "delete",
            url: baseUrladmin + "company/deleteCompanyMaster",
            data: bodyFormData,
            config: {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json; charset=utf-8",
                },
            },
        })
            .then((data) => {
                if (data.data.status == 200) {
                    alert("Customer Deleted Successfully");
                    window.location.reload(false);
                }
                else {
                    alert(data.data.message);
                    window.location.reload(false);
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    }
    useEffect(() => {
        fetch(baseUrladmin + "company/getAllCompanies", {
            headers: {
                Authorization: `Bearer ${AccessToken}`,
                "Content-type": "application/json; charset=UTF-8",
                "companyId": localStorage.getItem("companyId"),
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status == 200) {
                    setCompanytypes(data.data);
                    setLoading(false);
                    setShowdata(true);
                } else {
                    setLoading(false);
                    setShowdata(false);
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);
    const rows = posts;
    const columns = [
        { Header: "company Name", accessor: "companyName", align: "left" },
        { Header: "company Type", accessor: "companyType", align: "left" },
        { Header: "balance Amount", accessor: "balanceAmount", align: "center" },
        { Header: "company Email", accessor: "companyEmail", align: "left" },
        { Header: "company Address", accessor: "companyAddress", align: "center" },
        {
            Header: 'Action',
            accessor: "action",
            Cell: row => (
                <div>
                    <a style={{ cursor: 'pointer' }} onClick={e => editOpen(row.row.original)}><i class="material-icons">mode_edit</i></a>
                    &nbsp;<a style={{ cursor: 'pointer' }} onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) deleteOpen(row.row.original) }}>
                        <i class="material-icons">delete</i></a>
                </div>
            ),
            align: "center"
        },
    ];
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox display="flex" bgColor="info" coloredShadow="info" borderRadius="lg" justifyContent="space-between" alignItems="center" p={3}>
                                <MDBox>
                                    <MDTypography variant="h6" gutterBottom color="white">
                                        {heading}
                                    </MDTypography>
                                </MDBox>
                                <MDBox color="text" px={2} >
                                    {localStorage.getItem("roles").includes("ROLE_ADMIN") ? <MDButton style={{ color: "white" }} variant="outlined" type="button" color="info" size="small" onClick={transfer}>
                                        Add {heading}
                                    </MDButton> : null}
                                </MDBox>
                            </MDBox>
                            <MDBox pt={3}>
                                {showdata ? (
                                    <DataTable
                                        table={{ columns, rows }}
                                        isSorted={1}
                                        entriesPerPage={1}
                                        showTotalEntries={1}
                                        noEndBorder
                                    />
                                ) : loading ? (
                                    <Spinner />
                                ) : (
                                    <p>No Data found</p>
                                )
                                }
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}

export default Customers;
