import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import {
  baseUrl,
  baseUrlForUser,
  baseUrladmin,
} from "../../../assets/js/config/config.js";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "../style.css";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

function AddUser() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [rows, setPosts] = useState([]);
  const AccessToken = localStorage.getItem("accessToken");
  const fname = localStorage.getItem("firstName");
  const [destcmpid, setdestcmpid] = useState();
  const [devicegroup, setdeviceGroup] = useState();
  const [Deviceclassgroups, setDeviceclassgroups] = useState([]);
  const navigate = useNavigate();
  const [companytypes, setCompanytypes] = useState([]);
  const [Check, setCheck] = useState(false);
  const [role, setrole] = useState();

  useEffect(() => {
    fetch(baseUrladmin + "company/getAllCollegesUnderMe", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${AccessToken}`,
        "Content-type": "application/json; charset=UTF-8",
        companyId: localStorage.getItem("companyId"),
      },
      body: JSON.stringify({
        companyId: "",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == -1) {
          setCompanytypes([]);
        } else {
          setCompanytypes(data?.data);
        }
        setdestcmpid(data.data[0]?.companyId);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const handlSelect = (e) => {
    setrole(e.target.value);
  };
  const handleSelectCompany = (e) => {
    setdestcmpid(e.target.value);
  };
  useEffect(() => {}, []);
  const onSubmit = (data) => {
    const bodyFormData = {
      companyId: destcmpid || "",
      firstName: data.firstName,
      lastName: data.lastName,
      mobileNo: data.mobileNo,
      password: data.password,
      userName: data.userName,
      role: role,
    };
    axios({
      method: "post",
      url: baseUrladmin + "usermanager/registerUserByCompany",
      data: bodyFormData,
      config: {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
        },
      },
    })
      .then((data) => {
        if (data.data.status == 200) {
          alert("User Added Successfully");
          //navigate("/devicedata");
          window.location.reload(false);
        } else {
          alert(data.data.message);
          window.location.reload(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Register User
              </MDTypography>
            </MDBox>
            <div className="App container fpd col-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row" style={{ width: "965px" }}>
                  <div className="mb-2">
                    <label htmlFor="firstName">First Name</label>
                    <input
                      className="form-control"
                      placeholder=""
                      type="text"
                      name="firstName"
                      {...register("firstName", { required: true })}
                    />
                  </div>
                  <div className="mb-2">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                      className="form-control"
                      placeholder=""
                      type="text"
                      name="lastName"
                      {...register("lastName", { required: true })}
                    />
                  </div>
                  <div className="mb-2">
                    <label htmlFor="mobileNo">Mobile Number</label>
                    <input
                      className="form-control"
                      placeholder=""
                      type="text"
                      name="mobileNo"
                      {...register("mobileNo", { required: true })}
                    />
                  </div>
                </div>
                <div className="row" style={{ width: "965px" }}>
                  <div className="mb-2">
                    <label htmlFor="password">Password</label>
                    <input
                      className="form-control"
                      placeholder=""
                      type="password"
                      name="password"
                      {...register("password", { required: true })}
                    />
                  </div>

                  <div className="mb-2">
                    <label htmlFor="userName">User Name</label>
                    <input
                      className="form-control"
                      placeholder=""
                      type="text"
                      name="userName"
                      {...register("userName", { required: true })}
                    />
                  </div>
                  {/* <div className="mb-2">
                    <label htmlFor="role">Role</label>
                    <select onChange={handlSelect} style={{ width: "294px" }}>
                      <option value="cgroup">cgroup</option>
                      <option value="college">college</option>
                      <option value="admin">admin</option>
                    </select>
                  </div> */}
                  <div className="mb-2">
                    <label htmlFor="companyName">College</label>
                    <select
                      onChange={handleSelectCompany}
                      style={{ width: "294px" }}
                    >
                      {companytypes?.map((companytype) => (
                        <option value={companytype?.companyId}>
                          {companytype?.companyName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="mb-3">
                  <MDButton
                    variant="outlined"
                    type="submit"
                    color="info"
                    size="small"
                  >
                    Add
                  </MDButton>
                </div>
              </form>
            </div>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default AddUser;
