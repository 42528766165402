import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { baseUrl, baseUrladmin } from "../../../assets/js/config/config.js";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "../style.css";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

function AddTask() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [rows, setPosts] = useState([]);
    const AccessToken = localStorage.getItem("accessToken");
    const fname = localStorage.getItem("firstName");
    const navigate = useNavigate();
    const onSubmit = (data) => {
        const bodyFormData = {
            "taskName": data.taskName,
            "taskDescription": data.taskDescription,
            "isActive":true,
            "companyId":  localStorage.getItem("companyId")
        };
        axios({
            method: "post",
            url: baseUrladmin + "mytaskmaster/addTaskMaster",
            data: bodyFormData,
            config: {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json; charset=utf-8",
                    "companyId":  localStorage.getItem("companyId"),
                },
            },
        })
            .then((data) => {
                if (data.data.status == 200) {
                    alert("Task Added Successfully");
                    navigate("/mytasks");
                }
                else {
                    alert(data.data.message);
                    window.location.reload(false);
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={6}><Grid item xs={12}>
                <Card>
                    <MDBox
                        mx={2}
                        mt={3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info" >
                        <MDTypography variant="h6" color="white">
                            Add Task
                        </MDTypography>
                    </MDBox>
                    <div className='App container fpd col-6'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='mb-2'>
                                    <label htmlFor='taskName'>Task Name</label>
                                    <input
                                        className={`form-control ${errors.deviceName ? 'is-invalid' : ''}`}
                                        placeholder='Task Name'
                                        type='text'
                                        name='taskName'
                                        {...register("taskName", { required: true, minLength: 2, maxLength: 50 })}
                                    />
                                </div>
                            <div className='row' style={{ width: "965px" }}>
                                <div className='mb-2'>
                                    <label htmlFor='taskDescription'>Task Description</label>
                                    <input
                                        className={`form-control ${errors.deviceAddress ? 'is-invalid' : ''}`}
                                        placeholder='Description'
                                        type='text'
                                        name='taskDescription'
                                        {...register("taskDescription", {
                                            required: true,
                                            minLength: 10, maxLength: 500
                                        }
                                        )
                                        }
                                    />
                                </div>
                            </div>
                            <div className='mb-3'>
                                <MDButton variant="outlined" type="submit" color="info" size="small">
                                    Add Task
                                </MDButton>
                            </div>
                        </form>
                    </div>
                </Card>
            </Grid>
            </Grid>
        </DashboardLayout>
    );
}

export default AddTask;
