import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import axios from "axios";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { baseUrl, baseUrladmin } from "../../assets/js/config/config.js";
import moment from "moment";
import Icon from "@mui/material/Icon";
import { Link, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";

function Transactionreport() {
    const AccessToken = localStorage.getItem("accessToken");
    const [posts, setPosts] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        const bodyFormData = {
            "transactionType": "DEBIT"
        }
        axios({
            method: "post",
            url: baseUrladmin + "balance/getTransactionReportByType",
            data: bodyFormData,
            config: {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json; charset=utf-8",
                },
            },
        })
            .then((data) => {
                setPosts(data.data.data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);
    const transfer = (e) => {
        navigate("/balancetransfer");
    }
    const rows = posts;
    const columns = [
        { Header: "Company Name", accessor: "companyName", width: "10%", align: "left" },
        { Header: "Transferred By", accessor: "transferredBy", width: "10%", align: "left" },
        { Header: "Transaction Amount", accessor: "transAmount", width: "10%", align: "left" },
        { Header: "transactionType", accessor: "transactionType", width: "10%", align: "left" },
        {
            Header: "Transaction Time",
            accessor: d => {
                return moment(d.createdAt)
                    .local()
                    .format("DD-MM-YYYY hh:mm:ss a")
            },
            width: "10%",
            align: "left",
        },
    ];
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox display="flex" bgColor="info" coloredShadow="info" borderRadius="lg" justifyContent="space-between" alignItems="center" p={3}>
                                <MDBox>
                                    <MDTypography variant="h6" gutterBottom color="white">
                                        Transaction Report
                                    </MDTypography>
                                </MDBox>
                                <MDBox color="text" px={2} >
                                    <MDButton style={{color:"white"}} variant="outlined" type="button" color="info" size="small" onClick={transfer}>
                                        Transfer
                                    </MDButton>
                                </MDBox>
                            </MDBox>
                            <MDBox pt={3}>
                                <DataTable
                                    table={{ columns, rows }}
                                    isSorted={1}
                                    entriesPerPage={1}
                                    showTotalEntries={1}
                                    noEndBorder
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}

export default Transactionreport;
