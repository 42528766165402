import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import Spinner from "./spinner";
import {baseUrl,baseUrladmin} from "../../assets/js/config/config.js";

function Companies() {
  const AccessToken = localStorage.getItem("accessToken");
  const [rows, setPosts] = useState([]);
  const [companytypes, setCompanytypes] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetch(baseUrladmin+"apptypes/getAllAppTypes", {
      headers: {
        Authorization: `Bearer ${AccessToken}`,
        "Content-type": "application/json; charset=UTF-8",
        "companyId":  localStorage.getItem("companyId"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCompanytypes(data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
    fetch(baseUrladmin+"company/getCompanies", {
      headers: {
        Authorization: `Bearer ${AccessToken}`,
        "Content-type": "application/json; charset=UTF-8",
        "companyId":  localStorage.getItem("companyId"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPosts(data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  const handleSelect = (e) => {
    if (e.target.value === "all") {
      fetch(baseUrladmin + "company/getCompanies", {
        headers: {
          Authorization: `Bearer ${AccessToken}`,
          "Content-type": "application/json; charset=UTF-8",
          "companyId":  localStorage.getItem("companyId"),
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setPosts(data.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      fetch(
        baseUrladmin+`company/getCompaniesByType?companyTypeId=${e.target.value}`,
        {
          headers: {
            Authorization: `Bearer ${AccessToken}`,
            "Content-type": "application/json; charset=UTF-8",
            "companyId":  localStorage.getItem("companyId"),
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setPosts(data.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const columns = [
    { Header: "company Name", accessor: "companyName", width: "10%", align: "left" },
    { Header: "company Phone", accessor: "companyPhone", width: "10%", align: "left" },
    { Header: "company Type", accessor: "companyType", width: "10%", align: "left" },
    { Header: "company Email", accessor: "companyEmail", width: "10%", align: "left" },
    { Header: "company Tin", accessor: "companyTin", width: "10%", align: "left" },
    { Header: "postal Code", accessor: "postalCode", width: "10%", align: "left" },
  ];
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Companies
                </MDTypography>
              </MDBox>
              <MDBox mb={2} style={{ marginTop: "7px", marginLeft: "12px", fontSize: "15px" }}>
                Select Company Type &nbsp;
                <select style={{ width: "150px", height: "40px" }} onChange={handleSelect}>
                  <option value="all"> ------- All ------- </option>
                  {companytypes.map((companytype) => (
                    <option value={companytype.companyTypeId}>{companytype.companyType}</option>
                  ))}
                </select>
              </MDBox>
              <MDBox pt={3}>
                {!loading ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={1}
                    entriesPerPage={1}
                    showTotalEntries={1}
                    noEndBorder
                  />
                ) : (
                  <Spinner />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Companies;
